import React, { useState } from "react";
import styled from "styled-components";

import { Tooltip, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { ReactComponent as FaqIcon } from "../../Icons/question.svg";
import Img from "../../Images/Profile/avatar.png";

function Navbar({
  back_label,
  back_link,
  title,
  variant,
  username,
  tooltip,
  menuOpen,
  setMenuOpen,
  currentButton,
}) {
  const [activeButton, setActiveButton] = useState(currentButton);
  const handleButtonClick = (buttonNumber) => {
    setActiveButton(buttonNumber);
    navigate(`/profile/${buttonNumber === 1 ? "overview" : "statistics"}`);
  };

  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <Container $variant={variant}>
      <ExitContainer
        $theme={theme}
        className={variant === "1" ? "profile_version" : ""}
      >
        <ExitIcon onClick={() => navigate(back_link)}>
          <ChevronLeftIcon />
        </ExitIcon>
        {back_label}
      </ExitContainer>
      <Title $theme={theme} $variant={variant}>
        {title}
        {variant === "2" ? (
          <Tooltip
            title={tooltip}
            placement="bottom-end"
            componentsProps={{
              tooltip: {
                sx: {
                  minWidth: "350px",
                  borderRadius: "36px",
                  boxShadow: "0px 14px 36px 0px #DEE4E9",
                  bgcolor: "#fff",
                  color: theme.colors.grey1,
                  fontSize: "14px",
                  fontWeight: 300,
                  lineHeight: "150%",
                  padding: "21px 51px",
                },
              },
            }}
          >
            <FaqIconContainer>
              <FaqIcon />
            </FaqIconContainer>
          </Tooltip>
        ) : null}
      </Title>
      {variant === "1" ? (
        <UserInfo onClick={() => navigate(setMenuOpen(true))}>
          <img src={Img} alt="avatar" />
          <TextGroup $theme={theme}>
            <h6>Welcome</h6>
            <h4>{username}</h4>
          </TextGroup>
        </UserInfo>
      ) : null}

      {variant === "2" ? (
        <Switcher>
          <SwitchButton
            $theme={theme}
            onClick={() => handleButtonClick(1)}
            className={activeButton === 1 ? "active" : ""}
          >
            Overview
          </SwitchButton>
          <SwitchButton
            $theme={theme}
            onClick={() => handleButtonClick(2)}
            className={activeButton === 2 ? "active" : ""}
          >
            Sectional View
          </SwitchButton>
        </Switcher>
      ) : null}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 27px 42px;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 960px) {
    padding: 40px 15px 27px 15px;
  }

  @media (max-width: 768px) {
    background: #f6f7f9;
    box-shadow: 0px 14px 36px 0px #dee4e9;
    display: ${(props) => props.$variant === "2" && "grid"};
    grid-template-columns: auto auto;
    grid-template-rows: auto;
    grid-gap: 40px;
    place-items: center;
  }
`;

const ExitContainer = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 12px;
  color: ${(props) => props.$theme.colors.grey1};
  font-size: 15px;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: 1px;

  &.profile_version {
    opacity: 0;
    @media (max-width: 768px) {
      opacity: 1;
    }
    > div {
      display: none;
      @media (max-width: 768px) {
        display: inline-flex;
      }
    }
  }
`;

const ExitIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 47px;
  height: 47px;
  box-shadow: 0px 6px 7px -2px #d8d8d8;
  cursor: pointer;
  background-color: #fff;
  border-radius: 50%;
  transition: 0.2s ease-in-out;
  > svg {
    width: 25px;
  }

  &:hover {
    box-shadow: none;
  }
`;

const Title = styled.h2`
  color: ${(props) => props.$theme.colors.dark};
  font-family: ${(props) => props.$theme.secondaryFont};
  font-size: 24px;
  font-weight: 500;
  line-height: 110%;
  letter-spacing: 0.5px;
  @media (max-width: 768px) {
    display: ${(props) => (props.$variant === "2" ? "flex" : "none")};
    flex-direction: row-reverse;
    gap: 10px;
    font-size: 13px;
    font-weight: 700;
    line-height: 100%;
    width: 140px;
  }
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  > img {
    width: 47px;
    height: 47px;
    border-radius: 1000px;
    object-fit: cover;
    box-shadow: 0px 6px 14px 0px rgba(74, 91, 111, 0.23);
  }
`;

const TextGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > h6 {
    color: ${(props) => props.$theme.colors.grey2};
    font-size: 11px;
    font-weight: 100;
    line-height: 110%;
  }
  > h4 {
    color: ${(props) => props.$theme.colors.dark};
    font-size: 14px;
    font-weight: 700;
    line-height: 150%;
  }
`;

const Switcher = styled.div`
  display: flex;
  padding: 3px;
  align-items: flex-start;
  gap: 2px;
  border-radius: 1000px;
  background: #eef1f6;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.31) inset, 0px 3px 1px 0px #fff;
  grid-column: span 2;

  @media (max-width: 768px) {
    width: 90%;
  }
`;

const SwitchButton = styled.div`
  display: flex;
  padding: 6px 20px;
  justify-content: center;
  align-items: center;
  gap: 30px;
  color: ${(props) => props.$theme.colors.dark};
  font-size: 14px;
  font-weight: 300;
  line-height: 150%;
  border-radius: 1000px;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 50%;
  }

  &.active {
    background: ${(props) => props.$theme.colors.dark};
    color: #f6f7f9;
  }
`;

const FaqIconContainer = styled.div`
  width: 30px;
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  cursor: pointer;
  flex-shrink: 0;
  border-radius: 47.442px;
  background: #f6f7f9;
  box-shadow: 0px 5.5814px 13.95349px 0px rgba(74, 91, 111, 0.23);

  &:hover {
    background: #9da7b1;
  }
`;

export default Navbar;
