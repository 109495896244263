import React, { useState, useEffect } from "react";
import api from "../../../Components/API/apiService";
import { convertToMinutes, formatDateWithSuffix } from "../../../Utils/utils";
import styled from "styled-components";
import {
  TableContainer,
  Table as MuiTable,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  useTheme,
} from "@mui/material";

import TableHeadCell from "./TableHeadCell";

function Table() {
  const [orderBy, setOrderBy] = useState("userAssessmentID");
  const [order, setOrder] = useState("desc");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state

  const theme = useTheme();

  useEffect(() => {
    const getUserAssessmentData = async () => {
      try {
        const response = await api.get("assessment/user");
        if (response.successful) {
          setData(response.assessments);
        }
      } catch (error) {
        console.error("Error fetching assessment data:", error);
      } finally {
        setLoading(false); // Set loading to false after data fetch
      }
    };

    getUserAssessmentData();
  }, []);

  const sortRows = () => {
    return data.sort((a, b) => {
      const aValue = a[orderBy];
      const bValue = b[orderBy];
      if (order === "asc") {
        return aValue > bValue ? 1 : -1;
      } else {
        return aValue < bValue ? 1 : -1;
      }
    });
  };

  const sortedRows = sortRows();

  if (loading) {
    return <p>Loading...</p>; // Display loading message while data is being fetched
  }

  return (
    <StyledTableContainer>
      <MuiTable
        stickyHeader
        aria-label="assessment results"
        sx={{ borderCollapse: "collapse" }}
      >
        <TableHead sx={{ borderBottom: "8px solid transparent" }}>
          <TableRow>
            <TableHeadCell
              cell={{ id: "userAssessmentID", label: "ID" }}
              order={order}
              orderBy={orderBy}
              setOrder={setOrder}
              setOrderBy={setOrderBy}
            />
            <TableHeadCell
              cell={{ id: "performance", label: "Performance" }}
              order={order}
              orderBy={orderBy}
              setOrder={setOrder}
              setOrderBy={setOrderBy}
            />
            <TableHeadCell
              cell={{ id: "numberOfQuestions", label: "Questions" }}
              order={order}
              orderBy={orderBy}
              setOrder={setOrder}
              setOrderBy={setOrderBy}
            />
            <TableHeadCell
              cell={{ id: "duration", label: "Duration (minutes)" }}
              order={order}
              orderBy={orderBy}
              setOrder={setOrder}
              setOrderBy={setOrderBy}
            />
            <TableHeadCell
              cell={{ id: "answeredQuestionsCount", label: "Answered" }}
              order={order}
              orderBy={orderBy}
              setOrder={setOrder}
              setOrderBy={setOrderBy}
            />
            <TableHeadCell
              cell={{ id: "completedDateStamp", label: "Date Completed" }}
              order={order}
              orderBy={orderBy}
              setOrder={setOrder}
              setOrderBy={setOrderBy}
            />
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedRows.map((row) => (
            <StyledTableRow key={row.userAssessmentID}>
              <CustomTableCell component="th" scope="row" theme={theme}>
                {row.userAssessmentID}
              </CustomTableCell>
              <CustomTableCell theme={theme}>
                {row.performance ? row.performance : "-"}
              </CustomTableCell>
              <CustomTableCell theme={theme}>
                {row.numberOfQuestions}
              </CustomTableCell>
              <CustomTableCell theme={theme}>
                {row.duration ? convertToMinutes(row.duration) : "-"}
              </CustomTableCell>
              <CustomTableCell theme={theme}>
                {row.answeredQuestionsCount}
              </CustomTableCell>
              <CustomTableCell theme={theme}>
                {row.completedDateStamp ? (
                  formatDateWithSuffix(row.completedDateStamp)
                ) : (
                  <StatusButton
                    href={`/assessment/${row.userAssessmentID}`}
                    theme={theme}
                  >
                    Continue
                  </StatusButton>
                )}
              </CustomTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </MuiTable>
    </StyledTableContainer>
  );
}

const StyledTableContainer = styled(TableContainer)`
  max-height: 50vh;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #6dd4f0;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #4fa3c9;
  }
`;

const StyledTableRow = styled(TableRow)``;

const CustomTableCell = styled(TableCell)`
  padding: 8px 16px 10px 16px !important;
  color: ${(props) => props.theme.colors.grey1}!important;
  font-size: 14px !important;
  font-weight: 700 !important;
  line-height: 150% !important;
`;

const StatusButton = styled.a`
  min-width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 21px;
  max-width: fit-content;
  border-radius: 32px;
  background: #fff;
  box-shadow: 0px 4px 10px 0px rgba(74, 91, 111, 0.23);
  cursor: pointer;
  text-decoration: none;
  color: ${(props) => props.theme.colors.primary};
`;

export default Table;
