import React, { useState } from "react";
import styled from "styled-components";
import { useTheme } from "@mui/material";

import ButtonDark from "../Buttons/ButtonDark";
import Img from "../../Images/Home/Section6.png";

function Section6() {
  const theme = useTheme();

  return (
    <Container>
      <Content $theme={theme}>
        <Title $theme={theme}>Data driven.</Title>
        <Body $theme={theme}>
          Intellarity empowers you to take control of your learning journey. Our
          innovative platform continuously tracks your performance across
          various cognitive domains, helping you identify and leverage your
          unique strengths. By providing real-time feedback and personalized
          strategies, Intellarity not only highlights areas for improvement but
          also guides you through tailored exercises to enhance your cognitive
          abilities. With Intellarity, you're not just learning – you're
          evolving, turning insights into action, and unlocking your full
          potential with every interaction.
        </Body>
        {/* <ButtonDark link="">Learn More</ButtonDark> */}
      </Content>
      <ImgContainer>
        <img src={Img} />
      </ImgContainer>
      {/* <ButtonDark link="">Learn More</ButtonDark> */}
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  max-width: 1100px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  padding: 140px 15px;
  > button {
    display: none;
  }
  @media (max-width: 960px) {
    padding: 20px 15px 100px 15px;
    flex-direction: column;
    > button {
      display: block;
      width: fit-content;
      transform: scale(1.1);
      margin: 0 auto;
    }
  }
`;

const Content = styled.div`
  width: 35%;
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  > button {
    width: fit-content;
    font-size: 14px;
    font-style: normal;
    font-weight: 100;
    margin-top: 10px;
  }

  @media (max-width: 960px) {
    width: 100%;
    gap: 30px;
    margin-bottom: 20px;
    > button {
      display: none;
    }
  }
`;

const ImgContainer = styled.div`
  width: 65%;
  position: relative;
  > img {
    top: -10%;
    width: 105%;
    position: absolute;
  }
  @media (max-width: 960px) {
    width: 100%;
    > img {
      width: 100vw;
      margin-left: -20px;
      position: unset;
    }
  }
`;

const Title = styled.h1`
  color: ${(props) => props.$theme.colors.dark};
  font-family: ${(props) => props.$theme.secondaryFont};
  font-size: 45px;
  font-weight: 700;
  line-height: 100%;
  @media (max-width: 600px) {
    width: fit-content;
    font-size: 38px;
  }
`;
const Body = styled.p`
  max-width: 350px;
  color: ${(props) => props.$theme.colors.grey1};
  font-size: 14px;
  font-weight: 300;
  line-height: 150%;

  @media (max-width: 600px) {
    width: fit-content;
    font-size: 14px;
  }
`;

export default Section6;
