import React, { useState, useEffect } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useTheme } from "@mui/material";
import styled from "styled-components";
import api from "../../Components/API/apiService";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { ReactComponent as QuestionIcon } from "../../Icons/Profile Icons/Question.svg";
import { ReactComponent as DotIcon } from "../../Icons/Profile Icons/Dot.svg";

import ButtonDark from "../../Components/Buttons/ButtonDark";

const AssessmentInstruction = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { userAssessmentID } = useParams();
  const [assessment, setAssessment] = useState(null);
  console.log(223);

  useEffect(() => {
    if (userAssessmentID) {
      const fetchAssessmentQuestions = async () => {
        try {
          const response = await api.get(`assessment/get/${userAssessmentID}`);
          if (response && response.userAssessmentID) {
            setAssessment(response);
          } else {
            console.error("Failed to fetch assessment data");
          }
        } catch (error) {
          console.error("Error fetching assessment data:", error);
        }
      };

      fetchAssessmentQuestions();
    }
  }, [userAssessmentID]);

  return (
    <Container>
      <Header>
        <NavigationButton $theme={theme}>
          <NavIcon onClick={() => navigate("/profile/overview")}>
            <ChevronLeftIcon />
          </NavIcon>
          Home
        </NavigationButton>
        <Title $theme={theme}>Assessment Instruction</Title>
        <NavigationButton $theme={theme}>
          Start
          <NavIcon
            onClick={() => navigate(`/assessment/start/${userAssessmentID}`)}
          >
            <ChevronRightIcon />
          </NavIcon>
        </NavigationButton>
      </Header>
      <MobileTitle $theme={theme}>Assessment Instruction</MobileTitle>
      <InnerContainer>
        {assessment ? (
          <>
            <AssessmentTitle $theme={theme}>{assessment.title}</AssessmentTitle>
            <p>{assessment.description}</p>
            <ButtonDark link={`/assessment/start/${userAssessmentID}`}>
              Start Assessment
            </ButtonDark>
          </>
        ) : (
          <p>Loading...</p>
        )}
      </InnerContainer>
      <Footer $theme={theme}>
        <p>
          <NavLink to="/terms">Terms And Conditions</NavLink>
          <DotIcon />
          <NavLink to="/terms"> Policies</NavLink>
        </p>
        <SupportButton $theme={theme}>
          <div>
            <QuestionIcon />
          </div>
          <NavLink to="/support"> Support</NavLink>
        </SupportButton>
      </Footer>
    </Container>
  );
};

// Ensure only one default export

const Container = styled.div`
  padding: 0px 60px;
  @media (max-width: 960px) {
    padding: 0px;
  }
`;

const Header = styled.div`
  display: flex;
  padding: 50px 0px;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 960px) {
    padding: 50px 15px 35px 15px;
  }
`;

const Title = styled.h1`
  color: ${(props) => props.$theme.colors.dark};
  font-size: 24px;
  font-weight: 500;
  line-height: 110%;
  letter-spacing: 0.5px;
  font-family: ${(props) => props.$theme.secondaryFont};
  @media (max-width: 960px) {
    display: none;
  }
`;

const MobileTitle = styled.h1`
  color: ${(props) => props.$theme.colors.dark};
  font-size: 36px;
  font-weight: 700;
  line-height: 100%;
  font-family: ${(props) => props.$theme.secondaryFont};
  text-align: center;
  margin-bottom: 20px;
  display: none;
  @media (max-width: 960px) {
    display: block;
  }
`;

const NavigationButton = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 12px;
  color: ${(props) => props.$theme.colors.dark};
  font-size: 15px;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: 1px;
`;

const NavIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 47px;
  height: 47px;
  box-shadow: 0px 5px 10px 2px #d8d8d8;
  cursor: pointer;
  background-color: #fff;
  border-radius: 50%;
  transition: 0.2s ease-in-out;
  > svg {
    width: 25px;
  }
  &:hover {
    box-shadow: none;
  }
`;

const InnerContainer = styled.div`
  display: flex;
  width: 90%;
  padding: 30px 0;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  gap: 30px;
  > button {
    transform: scale(1.25);
  }
  @media (max-width: 1200px) {
    flex-direction: column;
    width: 100%;
    gap: 50px;
    padding: 60px 15px;
  }
`;

const AssessmentTitle = styled.h1`
  color: ${(props) => props.$theme.colors.dark};
  font-size: 30px;
  font-weight: 500;
  line-height: 110%;
  letter-spacing: 0.5px;
  font-family: ${(props) => props.$theme.secondaryFont};
`;

const Footer = styled.div`
  width: 100%;
  padding: 30px 60px 50px;
  position: absolute;
  left: 0;
  bottom: 0;
  float: right;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 960px) {
    flex-direction: column;
    row-gap: 40px;
  }
  > p {
    color: ${(props) => props.$theme.colors.dark};
    font-size: 15px;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    > a {
      transition: 0.3s ease-in-out;
      &:hover {
        opacity: 0.5;
      }
    }
    > svg {
      margin: 3px 15px 0 14px;
    }
  }
`;

const SupportButton = styled.div`
  display: flex;
  align-items: center;
  > div {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    flex-shrink: 0;
    border-radius: 47.442px;
    background: #fff;
    box-shadow: 0px 5.581px 13.953px 0px rgba(74, 91, 111, 0.23);
  }
  > a {
    transition: 0.3s ease-in-out;
    color: ${(props) => props.$theme.colors.dark};
    font-size: 15px;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    &:hover {
      opacity: 0.5;
    }
  }
`;

export default AssessmentInstruction;
