import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

function InnerFooter() {
  return (
    <Container>
      <Links>
        <NavLink to="/">Home</NavLink> /<NavLink to="/profile">Profile</NavLink>
        / <a>Test Result</a>
      </Links>
      <NavLink to="/support">Support</NavLink>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 42px 50px 42px;

  > a {
    color: #9da7b1;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-decoration-line: underline;
  }
`;

const Links = styled.div`
  display: inline-flex;
  gap: 5px;

  color: #9da7b1;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  > a {
    text-decoration-line: underline;
  }
`;

export default InnerFooter;
