import React from "react";
import styled from "styled-components";
import { useTheme } from "@mui/material";
import SouthEastIcon from "@mui/icons-material/SouthEast";

function Section7() {
  const theme = useTheme();

  return (
    <Container>
      <InnerContainer $theme={theme}>
        <Content>
          <Title $theme={theme}>
            Start Testing Right Now! New Opportunities for Personal and
            Professional Growth!
          </Title>
          <Body $theme={theme}>
            Open the door to your potential and learn more about your mind.
            Start testing now and move forward to reach new heights!
          </Body>
        </Content>
        {/* <StartButton>
          <span>Start Now</span>
          <ArrowTilted $theme={theme}>
            <SouthEastIcon />
          </ArrowTilted>
        </StartButton> */}
      </InnerContainer>
    </Container>
  );
}

const Container = styled.div`
  margin: 50px 5px;

  @media (max-width: 960px) {
    margin: 0 8px 200px 8px;
  }
`;

const InnerContainer = styled.div`
  width: 100%;
  max-width: 1100px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${(props) => props.$theme.colors.dark};
  border-radius: 36px;
  box-shadow: 0px 6px 14px 0px rgba(74, 91, 111, 0.23);
  margin: 0 auto 0 auto;
  padding: 76px 100px;

  @media (max-width: 960px) {
    padding: 30px 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  width: 60%;

  @media (max-width: 960px) {
    width: 100%;
  }
`;

const Title = styled.h1`
  color: #f6f7f9;
  font-size: 36px;
  font-weight: 700;
  line-height: 100%;
  font-family: ${(props) => props.$theme.secondaryFont};

  @media (max-width: 960px) {
    font-size: 21px;
    font-weight: 400;
    line-height: 110%;
    letter-spacing: 0.5px;
  }
`;

const Body = styled.p`
  color: ${(props) => props.$theme.colors.grey2};
  font-size: 15px;
  font-weight: 400;
  line-height: 150%;
`;

const StartButton = styled.div`
  width: 200px;
  height: 57px;
  display: inline-flex;
  padding-left: 0px;
  justify-content: center;
  align-items: center;
  gap: 30px;
  border-radius: 1000px;
  background: #fff;
  box-shadow: 0px 6px 14px 0px rgba(74, 91, 111, 0.23);
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 1px;
  cursor: pointer;

  @media (max-width: 960px) {
    margin: 10px auto 0 auto;
  }
`;

const ArrowTilted = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${(props) => props.$theme.colors.accent};
  width: 57px;
  height: 57px;
  margin: 0 -42px 0px -5px;
  box-shadow: -3px 5px 10px -2px ${(props) => props.$theme.colors.accent};
  > svg {
    color: #fff;
  }
`;

export default Section7;
