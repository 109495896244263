import React, { useState } from "react";
import styled from "styled-components";
import { useTheme } from "@mui/material";

import Img1 from "../../Images/Home/CognitiveTest1.png";
import Img2 from "../../Images/Home/CognitiveTest2.png";
import Img3 from "../../Images/Home/CognitiveTest3.png";

function Section3() {
  const theme = useTheme();
  return (
    <Container $theme={theme}>
      <InnerContainer>
        <ContainerLeft $theme={theme}>
          <Title $theme={theme}>Cognitive Blueprint?</Title>
          <img src={Img1} alt="cognitive test image 1" />
        </ContainerLeft>
        <ContainerRight $theme={theme}>
          <img src={Img2} alt="cognitive test image 2" />
          <Body $theme={theme}>
            A cognitive blueprint is a comprehensive, personalized map of an
            individual's unique learning processes and mental capabilities. It's
            like a detailed snapshot of how your brain processes, stores, and
            retrieves information. This blueprint encompasses various aspects of
            cognition, including memory capacity, attention span,
            problem-solving approaches, and preferred learning styles. At
            Intellarity, we use advanced AI and machine learning algorithms to
            create your cognitive blueprint based on a series of engaging
            assessments. This blueprint serves as the foundation for tailoring
            educational content and strategies specifically to your cognitive
            strengths and areas for improvement. By understanding your cognitive
            blueprint, we can optimize your learning experience, making it more
            efficient, effective, and enjoyable. Whether you're a student
            tackling complex subjects, a professional acquiring new skills, or
            anyone eager to expand their knowledge, your cognitive blueprint
            acts as a guide, helping you navigate your educational journey in
            the most personalized and impactful way possible.
          </Body>
          <img src={Img3} alt="cognitive test image 2" />
        </ContainerRight>
      </InnerContainer>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  position: relative;
  background-color: ${(props) => props.$theme.colors.dark};
  margin: 80px 0 0 0;
`;

const InnerContainer = styled.div`
  width: 100%;
  max-width: 1100px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  padding: 120px 15px;
  gap: 30px;
  width: 100%;
  @media (max-width: 960px) {
    padding: 60px 15px;
    flex-direction: column;
  }
`;

const ContainerLeft = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  row-gap: 13px;

  > img {
    position: absolute;
    width: 500px;
    top: 60%;
    z-index: 25;
  }

  @media (max-width: 960px) {
    width: 100%;
    align-items: center;
    text-align: center;
    > img {
      display: none;
    }
  }
`;

const ContainerRight = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  row-gap: 13px;

  > img:first-child {
    position: absolute;
    width: 260px;
    top: -15%;
    left: 70%;
  }

  > img:last-child {
    display: none;
  }

  @media (max-width: 960px) {
    width: 100%;
    padding-bottom: 120px;
    > img {
      display: none;
    }
    > img:last-child {
      width: calc(220px + 35vw);
      display: block;
      position: absolute;
      top: 80%;
      left: 48%;
      transform: translateX(-50%);
    }
  }
`;

const Title = styled.h1`
  color: #f6f7f9;
  font-size: 45px;
  font-weight: 700;
  line-height: 100%;
  font-family: ${(props) => props.$theme.secondaryFont};

  @media (max-width: 960px) {
    text-align: left;
    font-size: 36px;
    > br {
      display: none;
    }
  }
`;

const Body = styled.p`
  color: ${(props) => props.$theme.colors.grey2};
  font-size: 15px;
  font-weight: 300;
  line-height: 150%;

  @media (max-width: 600px) {
    font-size: 14px;
  }
`;

export default Section3;
