import React from "react";
import styled from "styled-components";
import { useTheme } from "@mui/material";
import { NavLink } from "react-router-dom";

function Header({ activeLink, setActiveLink }) {
  const theme = useTheme();

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <Container $theme={theme}>
        <h6>About Us</h6>
        <Title $theme={theme}>About Us</Title>
        <Links $theme={theme}>
          <NavLink to="/">Home</NavLink> /
          <NavLink to="/about">About Us</NavLink>
        </Links>
      </Container>
      <BottomBar>
        <BottomLinks>
          <a
            onClick={() => {
              setActiveLink("mission");
              scrollToSection("mission");
            }}
            className={activeLink === "mission" ? "active" : ""}
          >
            Mission
          </a>
          <a
            onClick={() => {
              setActiveLink("team");
              scrollToSection("team");
            }}
            className={activeLink === "team" ? "active" : ""}
          >
            Team
          </a>
          <a
            onClick={() => {
              setActiveLink("why");
              scrollToSection("why");
            }}
            className={activeLink === "why" ? "active" : ""}
          >
            Why We
          </a>
          <a
            onClick={() => {
              setActiveLink("faq");
              scrollToSection("faq");
            }}
            className={activeLink === "faq" ? "active" : ""}
          >
            F. A. Q.
          </a>
        </BottomLinks>
      </BottomBar>
    </>
  );
}

const Container = styled.div`
  display: flex;
  max-width: 1440px;
  padding: 30px 0px 20px 120px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 6px;
  margin: 20px auto 0px auto;

  > h6 {
    color: ${(props) => props.$theme.colors.grey2};
    font-size: 18px;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 1px;
  }

  @media (max-width: 960px) {
    padding: 30px 15px 50px 15px;
    flex-direction: column-reverse;
    gap: 30px;
  }
`;

const Title = styled.h1`
  color: ${(props) => props.$theme.colors.dark};
  font-family: ${(props) => props.$theme.secondaryFont};
  font-size: 51px;
  font-weight: 700;
  line-height: 100%;
  @media (max-width: 960px) {
    display: none;
  }
`;

const Links = styled.div`
  display: inline-flex;
  gap: 12px;

  color: ${(props) => props.$theme.colors.grey1};
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  > a {
    text-decoration-line: underline;
  }

  .activeLink {
    text-decoration-line: underline;
  }
`;

const BottomBar = styled.div`
  max-width: 1440px;
  margin-bottom: 70px;
  border-bottom: 1.5px solid #e2e5e9;
  display: flex;
  justify-content: flex-end;
  padding-right: 138px;

  @media (max-width: 960px) {
    display: none;
  }
`;

const BottomLinks = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 15px;

  > a {
    padding-bottom: 20px;
    cursor: pointer;
    &.active {
      position: relative;
      text-decoration: none;
      &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0px;
        width: 100%;
        height: 3px;
        border-radius: 33px 32px 0px 0px;
        background-color: #6dd4f0;
      }
    }
  }
`;

export default Header;
