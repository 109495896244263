import React from "react";
import styled from "styled-components";
import { useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";

function ButtonDark({children, link}) {
  const theme = useTheme();
  const navigate = useNavigate();

  return <Button onClick={() => navigate(link)} $theme={theme}>{children}</Button>;
}

const Button = styled.button`
  padding: 15px 24px;
  background-color: ${(props) => props.$theme.colors.dark};
  color: ${(props) => props.$theme.colors.white};
  font-size: 14px;
  border-radius: 50px;
  min-width: 95px;
  box-shadow: 0px 6px 14px 0px rgba(74, 91, 111, 0.23);
`;

export default ButtonDark;
