import React, { useState, useCallback } from "react";
import { PieChart, Pie, Cell, Label } from "recharts";
import styled from "styled-components";

const chart2_Data = [
  {
    name: "Dataset_1",
    value: 20,
    color: "#AEB2BE",
  },
  {
    name: "Dataset_2",
    value: 30,
    color: "#FFF",
  },
  {
    name: "Dataset_3",
    value: 15,
    color: "#616779",
  },
  {
    name: "Dataset_4",
    value: 20,
    color: "#202228",
  },
  {
    name: "Dataset_5",
    value: 15,
    color: "#6DD4F0",
  },
];

export default function SegmentPie({ legend }) {
  const [activeIndex, setActiveIndex] = useState(null);
  const onMouseOver = useCallback((index) => {
    setActiveIndex(index);
  }, []);
  const onMouseLeave = useCallback(() => {
    setActiveIndex(null);
  }, []);

  return (
    <>
      <div>
        <PieChart width={150} height={150} data={chart2_Data}>
          <Pie
            data={chart2_Data}
            innerRadius={0}
            outerRadius={70}
            isAnimationActive={false}
            stroke="#E9EDF1"
            fill="#E9EDF1"
            className="pie"
          />
        </PieChart>
        <PieChart width={140} height={140}>
          <Pie
            data={chart2_Data}
            innerRadius="45%"
            outerRadius="100%"
            dataKey="value"
            stroke="#E9EDF1"
            strokeWidth={4}
            className="pie"
          >
            <Label
              value={
                activeIndex !== null ? chart2_Data[activeIndex].value + "%" : ""
              }
              fontSize={16}
              position="center"
              fill="black"
            />
            {chart2_Data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                onMouseEnter={() => onMouseOver(index)}
                onMouseLeave={onMouseLeave}
                fill={entry.color}
                style={{
                  transition: "transform 0.3s ease-in-out",
                  transform:
                    activeIndex === index ? "scaleY(1.03)" : "scaleY(1)",
                }}
              />
            ))}
          </Pie>
        </PieChart>
      </div>
      {legend && (
        <Legend>
          {chart2_Data.map((item) => (
            <LegendItem key={item.name} className="legend_item">
              <div
                style={{ backgroundColor: item.color }}
                className="legend_item"
              ></div>
              <span>{item.name}</span>
            </LegendItem>
          ))}
        </Legend>
      )}
    </>
  );
}

const Legend = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  position: relative;
  left: 55%;
  margin-top: 10px;

  @media (max-width: 960px) {
    left: 5%;
    top: 60%;
  }
`;

const LegendItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
  min-width: 100px;
  > div {
    width: 42px;
    height: 21px;
    border-radius: 6px;
    border: 1px solid #e2e5e9;
  }

  > span {
    color: #515357;
    font-size: 12px;
    font-weight: 300;
    line-height: 150%;
  }
`;
