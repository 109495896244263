import { useTheme } from "@mui/material";
import { React, useState, useEffect } from "react";
import styled from "styled-components";
import Option from "./Extras/Option";

function Question({
  id,
  title,
  number,
  options,
  type,
  setAnswers,
  onSave,
  setOnSave,
}) {
  const theme = useTheme();

  const [selectedOptions, setSelectedOptions] = useState(
    options.map((element) => ({ title: element.title, selected: null }))
  );

  useEffect(() => {
    setAnswers((prevAnswers) =>
      prevAnswers.map((question) =>
        question.questionId === id
          ? { ...question, title: title, options: selectedOptions }
          : question
      )
    );
  }, [onSave]);

  return (
    <Container>
      <Header $theme={theme}>
        <div>
          <h4>{title}</h4>
          <h6>
            {type === "one"
              ? "Select One for each unless specified"
              : "Select many for each unless specified"}
          </h6>
        </div>
        <Number $theme={theme}>
          <span>0{number} </span>/ 06
        </Number>
      </Header>
      <Options>
        {options.map((option, i) => (
          <Option
            key={i}
            optionIndex={i}
            type={option.type}
            option={option}
            options={options}
            optionsLength={options.length}
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
          />
        ))}
      </Options>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  align-self: stretch;
`;

const Header = styled.div`
  display: flex;
  padding: 15px 30px;
  justify-content: space-between;
  align-items: center;
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 14px 36px 0px #dee4e9;
  width: 100%;

  > div {
    > h4 {
      font-family: ${(props) => props.$theme.secondaryFont};
      color: ${(props) => props.$theme.colors.dark};
      font-size: 21px;
      font-weight: 600;
      line-height: 110%;
      letter-spacing: 0.5px;
      margin-bottom: 5px;
    }
    > h6 {
      color: ${(props) => props.$theme.colors.grey2};
      font-size: 14px;
      font-weight: 300;
      line-height: 150%;
    }
  }
`;

const Number = styled.div`
  flex-shrink: 0;
  color: ${(props) => props.$theme.colors.grey2};
  font-size: 10px;
  font-weight: 400;
  line-height: 110%;
  display: inline;

  > span {
    font-family: ${(props) => props.$theme.secondaryFont};
    color: ${(props) => props.$theme.colors.dark};
    font-size: 21px;
    font-weight: 600;
    letter-spacing: 0.5px;
  }
`;

const Options = styled.div`
  display: flex;
  padding: 0px 30px;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  width: 100%;

  @media (max-width: 960px) {
    padding: 0px 15px;
  }
`;

export default Question;
