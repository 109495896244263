import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { useTheme } from "@mui/material";

import AssessmentCard from "../Cards/AssessmentCard";
import Img from "../../Images/Home/Assessment.png";
import ButtonDark from "../Buttons/ButtonDark";

function Section5() {
  const theme = useTheme();
  const cardRef = useRef();
  const [activeDot, setActiveDot] = useState(0);
  const [lockDot, setLockDot] = useState(false);

  const scrollToCard = (index) => {
    cardRef.current.children[index].scrollIntoView({
      behavior: "smooth",
      block: "nearest",
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      const container = cardRef.current;

      const index = Math.round(container.scrollLeft / 300);

      if (
        container.scrollLeft + container.offsetWidth >=
        container.scrollWidth
      ) {
        setActiveDot(cardsData.length - 1);
      } else {
        setActiveDot(index);
      }
    };

    cardRef.current && cardRef.current.addEventListener("scroll", handleScroll);

    return () => {
      cardRef.current &&
        cardRef.current.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const cardsData = [
    {
      title: "Assessment Name",
      text: "Porem ipsum dolor sit amet, consectetur adipiscing elit.",
      tags: ["Memory", "120 Questions", "45 min."],
      image: Img,
      link: "https://example.com/card1",
      learn_more: "#",
    },
    {
      title: "Assessment Name",
      text: "Porem ipsum dolor sit amet, consectetur adipiscing elit.",
      tags: ["Memory", "120 Questions", "45 min."],
      image: Img,
      link: "https://example.com/card3",
      learn_more: "#",
    },
    {
      title: "Assessment Name",
      text: "Porem ipsum dolor sit amet, consectetur adipiscing elit.",
      tags: ["Memory", "120 Questions", "45 min."],
      image: Img,
      link: "https://example.com/card3",
      learn_more: "#",
    },
    {
      title: "Assessment Name",
      text: "Porem ipsum dolor sit amet, consectetur adipiscing elit.",
      tags: ["Memory", "120 Questions", "45 min."],
      image: Img,
      link: "https://example.com/card3",
      learn_more: "#",
    },
    {
      title: "Assessment Name",
      text: "Porem ipsum dolor sit amet, consectetur adipiscing elit.",
      tags: ["Memory", "120 Questions", "45 min."],
      image: Img,
      link: "https://example.com/card3",
      learn_more: "#",
    },
    {
      title: "Assessment Name",
      text: "Porem ipsum dolor sit amet, consectetur adipiscing elit.",
      tags: ["Memory", "120 Questions", "45 min."],
      image: Img,
      link: "https://example.com/card3",
      learn_more: "#",
    },
  ];

  return (
    <Container $theme={theme}>
      <TopContainer>
        <Title $theme={theme}>Recommended Assessments</Title>
        <Body $theme={theme}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu
          turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec
          fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus
          elit sed risus.
        </Body>
      </TopContainer>
      <Cards $cardsData={cardsData} ref={cardRef}>
        {cardsData.map((card, index) => (
          <AssessmentCard
            key={index}
            title={card.title}
            text={card.text}
            tags={card.tags}
            image={card.image}
            link={card.link}
            learn_more={card.learn_more}
          />
        ))}
      </Cards>
      <MobileSectionFooter>
        <DotNavigation>
          {cardsData.map((_, index) => (
            <Dot
              key={index}
              $active={activeDot === index}
              onClick={() => scrollToCard(index)}
              $theme={theme}
            />
          ))}
        </DotNavigation>
        <ButtonDark link="/assessments">View All Assessments</ButtonDark>
      </MobileSectionFooter>
      <ButtonDark link="/assessments">View All</ButtonDark>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  position: relative;
  background: linear-gradient(
    to bottom,
    ${(props) => props.$theme.colors.dark} 90%,
    #f6f7f9 90%
  );
  margin-bottom: 180px;
  > button {
    margin-top: 70px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%) scale(1.1);
  }

  @media (max-width: 960px) {
    background: linear-gradient(
      to bottom,
      ${(props) => props.$theme.colors.dark} 70%,
      #f6f7f9 70%
    );
    margin-bottom: 100px;
    > button {
      display: none;
    }
  }
`;

const TopContainer = styled.div`
  width: 100%;
  max-width: 1100px;
  overflow: hidden;
  display: flex;
  align-items: center;
  column-gap: 35%;
  margin: 0 auto 0 auto;
  padding: 120px 15px;
  @media (max-width: 960px) {
    padding: 60px 15px;
    gap: 15px;
    flex-direction: column;
  }
`;

const Title = styled.h1`
  color: #f6f7f9;
  font-size: 45px;
  font-weight: 700;
  line-height: 100%;
  font-family: ${(props) => props.$theme.secondaryFont};

  @media (max-width: 960px) {
    text-align: left;
    font-size: 36px;
  }
`;

const Body = styled.p`
  color: ${(props) => props.$theme.colors.grey2};
  font-size: 15px;
  font-weight: 300;
  line-height: 150%;

  @media (max-width: 600px) {
    font-size: 14px;
  }
`;

const Cards = styled.div`
  width: 100%;
  max-width: 1050px;
  display: grid;
  margin: 0 auto 0 auto;
  grid-template-columns: repeat(
    ${(props) => Math.round(props.$cardsData.length / 2)},
    minmax(305px, 320px)
  );
  grid-template-rows: repeat(2, 1fr);
  row-gap: 40px;
  column-gap: 25px;
  overflow: hidden;
  place-content: center;
  grid-auto-flow: column;
  padding-bottom: 20px;

  @media (max-width: 1100px) {
    grid-template-columns: repeat(
      ${(props) => Math.round(props.$cardsData.length / 2)},
      minmax(300px, 305px)
    );
  }

  @media (max-width: 960px) {
    grid-template-columns: repeat(
      ${(props) => props.$cardsData.length},
      minmax(285px, 1fr)
    );
    grid-template-rows: repeat(1, 1fr);
    place-content: flex-start;
    padding-left: 15px;
    white-space: nowrap;
    overflow-x: auto;
    scroll-behavior: smooth;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

const MobileSectionFooter = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  padding: 50px 0px 0 0px;
  > button {
    transform: scale(1.1);
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
  }
  @media (min-width: 960px) {
    display: none;
  }
`;

const Dot = styled.div`
  width: ${(props) => (props.$active ? "30px" : "10px")};
  height: 10px;
  border-radius: 38px;
  margin: 0 3px;
  background: ${(props) =>
    props.$active
      ? "radial-gradient(130.62% 130.62% at 50% 0%, #6DD4F0 0%, #6DD4F0 13.02%, #1A839F 100%)"
      : "#E2E5E9"};
  cursor: pointer;
`;

const DotNavigation = styled.div`
  justify-content: center;
  margin: 30px 0;
  display: flex;
`;

export default Section5;
