import React from "react";
import styled from "styled-components";
import { useTheme } from "@mui/material";
import Background from "../../Images/Home/Section4.png";
import { ReactComponent as StepIcon } from "../../Icons/Step.svg";
import ButtonDark from "../Buttons/ButtonDark";

function Section4() {
  const theme = useTheme();

  return (
    <Container $theme={theme}>
      <InnerContainer>
        <Title $theme={theme}>
          How<br></br> It Works?
        </Title>
        <Steps>
          <Step $theme={theme}>
            <StepIcon />
            <h4>Step 1</h4>
            <h1>Assessments</h1>
            <p>
              Take a series of engaging online assessments designed to evaluate
              various aspects of your cognitive abilities.
            </p>
          </Step>
          <Step $theme={theme}>
            <StepIcon style={{ left: "23%" }} />
            <h4>Step 2</h4>
            <h1>Blueprint</h1>
            <p>
              Based on your results, we generate a unique cognitive blueprint
              tailored to your learning style and abilities.
            </p>
          </Step>
          <Step $theme={theme}>
            <StepIcon style={{ left: "57%" }} />
            <h4>Step 3</h4>
            <h1>Optimization</h1>
            <p>
              Use your cognitive blueprint to access personalized learning paths
              and strategies, enhancing your educational journey.
            </p>
          </Step>
        </Steps>
        {/* <ButtonDark>Learn More</ButtonDark> */}
      </InnerContainer>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  position: relative;
  background: url(${Background}) no-repeat;
  mix-blend-mode: multiply;
  background-size: cover;
  padding: 220px 0 120px 0px;
`;

const InnerContainer = styled.div`
  width: 100%;
  max-width: 1100px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 0 15px;
  row-gap: 60px;
  position: relative;
  z-index: 100;
  overflow: visible;

  > button {
    margin-top: 20px;
    width: fit-content;
    align-self: center;
    color: #f6f7f9;
    font-size: 14px;
    font-weight: 300;
    line-height: 150%;

    @media (max-width: 960px) {
      transform: scale(1.2);
    }
  }
`;

const Title = styled.h1`
  color: ${(props) => props.$theme.colors.dark};
  text-align: right;
  font-size: 45px;
  font-weight: 700;
  line-height: 100%;
  font-family: ${(props) => props.$theme.secondaryFont};
  > br {
    display: none;
  }

  @media (max-width: 960px) {
    text-align: right;
    font-size: 36px;
    > br {
      display: block;
    }
  }
`;

const Steps = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  gap: 51px;
  margin-top: 30px;

  @media (max-width: 960px) {
    flex-direction: column;
    padding: 0 45px;
    margin-top: 0px;

    > div:nth-child(1) {
      align-self: flex-start;
      > svg {
        left: -75px !important;
        top: 13%;
      }
    }
    > div:nth-child(3) {
      align-self: flex-start;
      > svg {
        left: -75px !important;
        top: 61%;
      }
    }
    > div:nth-child(2) {
      align-self: flex-end;
      text-align: right;
      align-items: flex-end;
      > svg {
        left: unset !important;
        right: -75px;
        top: 36.5%;
        transform: scaleX(-1);
      }
    }
  }
`;

const Step = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;

  > svg {
    position: absolute;
    left: -11%;
    top: 24%;
    width: 169px;
    height: 69px;
    opacity: 0.8;
    z-index: 110;
  }

  > h4 {
    color: ${(props) => props.$theme.colors.grey1};
    font-size: 18px;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 1px;
  }

  > h1 {
    color: ${(props) => props.$theme.colors.dark};
    font-family: ${(props) => props.$theme.secondaryFont};
    font-size: 36px;
    font-weight: 700;
    line-height: 100%;

    @media (max-width: 960px) {
      font-size: 24px;
      width: 150px;
    }
  }

  > p {
    color: ${(props) => props.$theme.colors.grey1};
    font-size: 15px;
    font-weight: 300;
    line-height: 150%;
    @media (max-width: 960px) {
      font-size: 14px;
    }
  }
`;

export default Section4;
