import React, { useState } from "react";
import styled from "styled-components";

import { TableCell, TableSortLabel, useTheme } from "@mui/material";

import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function TableHeadCell({ cell, length, order, orderBy, setOrder, setOrderBy }) {
  const [currentOrder, setCurrentOrder] = useState("desc");
  const theme = useTheme();

  const CustomSortIcon = () => {
    return (
      <IconContainer>
        {currentOrder === "asc" ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </IconContainer>
    );
  };

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setCurrentOrder(isAsc ? "desc" : "asc");
  };
  return (
    <CustomTableCell
      key={cell.id}
      sx={{
        borderRadius:
          cell.id === 0
            ? "12px 0 0 12px"
            : cell.id === length - 1
            ? "0 12px 12px 0"
            : "",
      }}
      $theme={theme}
    >
      <TableSortLabel
        active={orderBy === cell.id}
        onClick={() => handleSort(cell.id)}
        IconComponent={CustomSortIcon}
      >
        {cell.label}
      </TableSortLabel>
    </CustomTableCell>
  );
}

const CustomTableCell = styled(TableCell)`
  background: #e2e5e9 !important;
  border: none !important;
  color: ${(props) => props.$theme.colors.grey1}!important;
  font-size: 12px !important;
  font-weight: 300 !important;
  line-height: 150% !important;
  margin-bottom: 10px;
`;

const IconContainer = styled.div`
  width: 21px;
  height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  background: #fff;
  border-radius: 32px;
  filter: drop-shadow(0px 2.77px 6.464px rgba(74, 91, 111, 0.23));

  > svg {
    width: 20px;
  }
`;


export default TableHeadCell;
