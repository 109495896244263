import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import "./Styles/App.css";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./Utils/theme";
import ScrollToTop from "./Components/ScrollToTop"; // Import your ScrollToTop component

import Home from "./Pages/Home";
import Login from "./Pages/Login";
import Signup from "./Pages/Signup";
import NavBar from "./Components/NavBar";
import Footer from "./Components/Footer";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import Terms from "./Pages/Terms";
import Profile from "./Pages/Profile/index";
import QuestionTemplate from "./Pages/Questions/QuestionTemplate";
import AssessmentTemplate from "./Pages/Questions/AssessmentTemplate";
import AssessmentInstruction from "./Pages/Questions/AssessmentInstruction";
import Statistics from "./Pages/Profile/Statistics";
import Overview from "./Pages/Profile/Overview";
import BrainSection from "./Components/Modals/BrainSection";

function App() {
  const [loggedIn, setLoggedIn] = useState(true);
  const location = useLocation();

  const excludedPaths = ["/assessment"];
  const shouldExcludeNavBarFooter = excludedPaths.some((path) =>
    location.pathname.startsWith(path)
  );

  useEffect(() => {
    switch (location.pathname) {
      case "/about":
        document.title = "About - Intellarity";
        break;
      case "/contact":
        document.title = "Contact - Intellarity";
        break;
      case "/terms":
        document.title = "Terms - Intellarity";
        break;
      case "/profile":
        document.title = "Profile - Intellarity";
        break;
      case "/login":
        document.title = "Login - Intellarity";
        break;
      case "/signup":
        document.title = "Signup - Intellarity";
        break;
      default:
        document.title = "Intellarity";
    }
  }, [location.pathname]);

  return (
    <ThemeProvider theme={theme}>
      <ScrollToTop />
      <div className="App">
        {!shouldExcludeNavBarFooter && (
          <NavBar loggedIn={loggedIn} setLoggedIn={setLoggedIn} />
        )}

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/terms" element={<Terms />} />
          <Route
            path="/login"
            element={
              !loggedIn ? (
                <Login setLoggedIn={setLoggedIn} />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="/signup"
            element={
              !loggedIn ? (
                <Signup setLoggedIn={setLoggedIn} />
              ) : (
                <Navigate to="/" />
              )
            }
          />

          <Route
            path="/profile"
            element={
              loggedIn ? (
                <Profile loggedIn={loggedIn} />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/profile/overview"
            element={loggedIn ? <Overview /> : <Navigate to="/login" />}
          />
          <Route
            path="/profile/statistics"
            element={loggedIn ? <Statistics /> : <Navigate to="/login" />}
          />
          <Route
            path="/profile/statistics/brain/*"
            element={loggedIn ? <BrainSection /> : <Navigate to="/login" />}
          />
          <Route
            path="/assessment/:userAssessmentID"
            element={
              loggedIn ? <AssessmentTemplate /> : <Navigate to="/login" />
            }
          />
          <Route
            path="/assessment/instructions/:userAssessmentID"
            element={
              loggedIn ? <AssessmentInstruction /> : <Navigate to="/login" />
            }
          />
          <Route
            path="/assessment/start/:userAssessmentID"
            element={loggedIn ? <QuestionTemplate /> : <Navigate to="/login" />}
          />

          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        {!shouldExcludeNavBarFooter && <Footer />}
      </div>
    </ThemeProvider>
  );
}

export default App;
