import { authService } from "./authService";
let BASE_URL;
console.log("------------" + JSON.stringify(process.env) + "----------");
if (process.env.REACT_APP_NODE_ENV === "production") {
  BASE_URL = "https://intellarity.com/api/1.0";
} else {
  BASE_URL = "http://localhost:5031/api/1.0";
}

async function post(endpoint, data) {
  const headers = {
    Accept: "application/json, text/plain, */*",
    "Content-Type": "application/json",
  };

  const token = authService.getToken();
  if (token) {
    headers["x-user-session-token"] = token;
  }

  try {
    const response = await fetch(`${BASE_URL}/${endpoint}`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data),
    });

    if (!response) {
      throw new Error("Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error("Error during fetch operation:", error.message);
    throw error; // Re-throw the error to be handled in the calling function
  }
}

async function get(endpoint, params = {}) {
  const headers = {
    Accept: "application/json, text/plain, */*",
    "Content-Type": "application/json",
  };

  const token = authService.getToken();
  if (token) {
    headers["x-user-session-token"] = token;
  }

  const queryString = new URLSearchParams(params).toString();

  const response = await fetch(`${BASE_URL}/${endpoint}?${queryString}`, {
    method: "GET",
    headers: headers,
  });

  return await response.json();
}

export default { post, get };
