import { useTheme } from "@mui/material";

import React from "react";
import styled from "styled-components";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import LogoImg from "../Images/Logo.png";
import { useNavigate } from "react-router-dom";

import { ReactComponent as FacbookIcon } from "../Icons/Socials/facebook.svg";
import { ReactComponent as XIcon } from "../Icons/Socials/x.svg";
import { ReactComponent as GooglePlusIcon } from "../Icons/Socials/g+.svg";
import { ReactComponent as PinterestIcon } from "../Icons/Socials/pintrest.svg";

const Footer = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Container>
      <MainContent>
        <LogoContainer>
          <Logo src={LogoImg} alt="Logo" />
          <LogoText $theme={theme}>
            Intellarity: Revolutionizing education through AI-driven
            personalized learning. Born from personal experience, we create
            adaptive learning paths tailored to each individual's unique
            cognitive blueprint, making quality education accessible and
            effective for all learners, regardless of their learning style or
            challenges.
          </LogoText>
        </LogoContainer>
        <Line />
        <LinksContainer>
          {/* <Column $theme={theme}>
            <h4>Quick Links</h4>
            <FooterLink $theme={theme} onClick={() => navigate("/")}>
              Link 1
              <ArrowForwardIcon />
            </FooterLink>
            <FooterLink $theme={theme} onClick={() => navigate("/")}>
              Link 2
              <ArrowForwardIcon />
            </FooterLink>
            <FooterLink $theme={theme} onClick={() => navigate("/")}>
              Link 3
              <ArrowForwardIcon />
            </FooterLink>
            <FooterLink $theme={theme} onClick={() => navigate("/")}>
              Link 4
              <ArrowForwardIcon />
            </FooterLink>
            <FooterLink $theme={theme} onClick={() => navigate("/")}>
              Link 3
              <ArrowForwardIcon />
            </FooterLink>
            <FooterLink $theme={theme} onClick={() => navigate("/")}>
              Link 3
              <ArrowForwardIcon />
            </FooterLink>
          </Column>
          <Column $theme={theme}>
            <h4>Quick Links</h4>
            <FooterLink $theme={theme} onClick={() => navigate("/")}>
              Link 5
              <ArrowForwardIcon />
            </FooterLink>
            <FooterLink $theme={theme} onClick={() => navigate("/")}>
              Link 6
              <ArrowForwardIcon />
            </FooterLink>
            <FooterLink $theme={theme} onClick={() => navigate("/")}>
              Link 7
              <ArrowForwardIcon />
            </FooterLink>
            <FooterLink $theme={theme} onClick={() => navigate("/")}>
              Link 8
              <ArrowForwardIcon />
            </FooterLink>
            <FooterLink $theme={theme} onClick={() => navigate("/")}>
              Link 3
              <ArrowForwardIcon />
            </FooterLink>
            <FooterLink $theme={theme} onClick={() => navigate("/")}>
              Link 3
              <ArrowForwardIcon />
            </FooterLink>
          </Column>
          <Column $theme={theme}>
            <h4>Quick Links</h4>
            <FooterLink $theme={theme} onClick={() => navigate("/")}>
              Link 9
              <ArrowForwardIcon />
            </FooterLink>
            <FooterLink $theme={theme} onClick={() => navigate("/")}>
              Link 10
              <ArrowForwardIcon />
            </FooterLink>
            <FooterLink $theme={theme} onClick={() => navigate("/")}>
              Link 11
              <ArrowForwardIcon />
            </FooterLink>
            <FooterLink $theme={theme} onClick={() => navigate("/")}>
              Link 3
              <ArrowForwardIcon />
            </FooterLink>
            <FooterLink $theme={theme} onClick={() => navigate("/")}>
              Link 12
              <ArrowForwardIcon />
            </FooterLink>
            <FooterLink $theme={theme} onClick={() => navigate("/")}>
              Link 3
              <ArrowForwardIcon />
            </FooterLink>
          </Column> */}
          <Column $theme={theme}>
            <h4>Quick Links</h4>
            <FooterLink $theme={theme} onClick={() => navigate("/")}>
              Home
              <ArrowForwardIcon />
            </FooterLink>
            <FooterLink $theme={theme} onClick={() => navigate("/about")}>
              About
              <ArrowForwardIcon />
            </FooterLink>
            <FooterLink $theme={theme} onClick={() => navigate("/contact")}>
              Contact
              <ArrowForwardIcon />
            </FooterLink>
          </Column>
        </LinksContainer>
      </MainContent>
      <Line />
      <BottomBar>
        <CopyrightText $theme={theme}>
          Copyrights ©2024 <b>Intellarity</b>. All rights reserved.
        </CopyrightText>
        <IconsContainer>
          <FacbookIcon />
          <XIcon />
          <GooglePlusIcon />
          <PinterestIcon />
        </IconsContainer>
      </BottomBar>
    </Container>
  );
};

const Container = styled.footer`
  padding: 90px 60px 51px 60px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 51px;

  @media (max-width: 768px) {
    gap: 30px;
    padding: 63px 15px;
  }
`;

const MainContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
    row-gap: 20px;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Logo = styled.img`
  width: 170px;
  margin-right: 10px;
`;

const LogoText = styled.span`
  max-width: 350px;
  color: ${(props) => props.$theme.colors.grey1};
  font-size: 15px;
  font-weight: 100;
  line-height: 150%;
`;

const LinksContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 60%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Column = styled.div`
  display: flex;
  padding: 0 16px 15px 16px;
  flex-direction: column;
  align-items: flex-start;
  > h4 {
    color: ${(props) => props.$theme.colors.grey1};
    font-family: ${(props) => props.$theme.secondaryFont};
    font-size: 18px;
    font-weight: 800;
    line-height: 140%;
    margin-bottom: 20px;
  }

  @media (max-width: 960px) {
    padding: 0 8px 15px 8px;
  }
`;

const BottomBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    gap: 50px;
  }
`;

const CopyrightText = styled.span`
  color: ${(props) => props.$theme.colors.grey2};
  font-size: 15px;
  font-weight: 400;
  line-height: 150%;

  > b {
    color: ${(props) => props.$theme.colors.grey1};
  }
`;

const IconsContainer = styled.div`
  display: flex;
  display: flex;
  align-items: flex-start;
  gap: 21px;

  > svg {
    width: 27px;
    height: 27px;
    cursor: pointer;
    filter: drop-shadow(0px 0px 0px rgba(28, 21, 0, 0.07))
      drop-shadow(0px 1px 2px rgba(28, 21, 0, 0.07))
      drop-shadow(2px 3px 3px rgba(28, 21, 0, 0.06))
      drop-shadow(4px 6px 4px rgba(28, 21, 0, 0.04))
      drop-shadow(6px 11px 5px rgba(28, 21, 0, 0.01))
      drop-shadow(10px 17px 5px rgba(28, 21, 0, 0));
  }
`;

const FooterLink = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  display: block;
  margin-bottom: 8px;
  text-decoration: none;
  color: ${(props) => props.$theme.colors.grey2};
  font-size: 14.5px;
  font-weight: 300;
  line-height: 150%;
  cursor: pointer;
  transition: 0.25s ease-in-out all;
  > svg {
    transform: translateX(0px) translateY(6px);
    opacity: 0;
    transition: 0.35s ease-in-out all;
    color: ${(props) => props.$theme.colors.dark};
    width: 14px;
  }

  &:hover {
    color: ${(props) => props.$theme.colors.dark};
    > svg {
      opacity: 1;
      transform: translateX(10px) translateY(6px);
    }
  }
`;

const Line = styled.div`
  width: 100%;
  margin: 20px auto;
  height: 1px;
  background-color: #e2e5e9;
  filter: drop-shadow(0px 2px 1px #fff);

  @media (min-width: 768px) {
    display: none;
  }
`;

export default Footer;
