import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  background: "#F6F7F9",
  palette: {
    primary: {
      main: "#202228",
    },
    secondary: {
      main: "#515357",
    },
  },
  colors: {
    white: "#F6F7F9",
    blue: "#198AA9",
    accent: "#6DD4F0",
    grey1: "#515357",
    grey2: "#9DA7B1",
    dark: "#202228",
  },
  typography: {
    fontFamily: "Helvetica",
  },
  secondaryFont: "'Bricolage Grotesque', sans-serif",
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1250,
      xl: 1920,
    },
  },
  type: "light",
});

export default theme;
