// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz@12..96&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*   Common Styles used throughout the App */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Helvetica", sans-serif;
  outline: none;
  border: none;
  text-decoration: none;
  color: inherit;
}

body {
  background-color: #f6f7f9;
  overflow-x: hidden;
  margin: 0 auto;
  max-width: 1600px !important;
}

.App {
  overflow-x: hidden;
}

button {
  cursor: pointer;
}

/* Charts */

.recharts-radar-dots circle {
  fill: #616779;
  stroke: transparent;
  fill-opacity: 1;
  r: 4;
}
`, "",{"version":3,"sources":["webpack://./src/Styles/App.css"],"names":[],"mappings":"AAAA,4CAA4C;AAG5C;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;EACtB,oCAAoC;EACpC,aAAa;EACb,YAAY;EACZ,qBAAqB;EACrB,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,cAAc;EACd,4BAA4B;AAC9B;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB;;AAEA,WAAW;;AAEX;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,IAAI;AACN","sourcesContent":["/*   Common Styles used throughout the App */\n\n@import url(\"https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz@12..96&display=swap\");\n* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n  font-family: \"Helvetica\", sans-serif;\n  outline: none;\n  border: none;\n  text-decoration: none;\n  color: inherit;\n}\n\nbody {\n  background-color: #f6f7f9;\n  overflow-x: hidden;\n  margin: 0 auto;\n  max-width: 1600px !important;\n}\n\n.App {\n  overflow-x: hidden;\n}\n\nbutton {\n  cursor: pointer;\n}\n\n/* Charts */\n\n.recharts-radar-dots circle {\n  fill: #616779;\n  stroke: transparent;\n  fill-opacity: 1;\n  r: 4;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
