import React from "react";
import styled from "styled-components";
import Section1 from "../Components/Home_Sections/Section1";
import Section2 from "../Components/Home_Sections/Section2";
import Section3 from "../Components/Home_Sections/Section3";
import Section4 from "../Components/Home_Sections/Section4";
import Section5 from "../Components/Home_Sections/Section5";
import Section6 from "../Components/Home_Sections/Section6";
import Section7 from "../Components/Home_Sections/Section7";

function Home() {
  return (
    <Container>
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section6 />
      <Section7 />
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  overflow: hidden;
`;

export default Home;
