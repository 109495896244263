import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTheme } from "@mui/material";

import Data from "../../Utils/BrainData.json";
import styled from "styled-components";
import BackgroundImg from "../../Images/Profile/ModalBack.png";
import BackgroundImg2 from "../../Images/Profile/ModalBack2.png";
import BrainImg from "../../Images/Profile/ModalImg.png";

import CloseIcon from "@mui/icons-material/Close";

function BrainSection() {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const [isPageVisible, setIsPageVisible] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsPageVisible(true);
    }, 100);
  }, []);

  const handleExit = () => {
    setIsPageVisible(false);
    setTimeout(() => {
      navigate("/profile/statistics/");
    }, 400);
  };

  const [currentBrainSection, setCurrentBrainSection] = useState(null);

  useEffect(() => {
    const pathAfterBrain = location.pathname.split("brain/")[1];
    const decodedPath = pathAfterBrain.replace(/%20/g, " ");
    setCurrentBrainSection(
      Data.filter((el) => {
        return el.title === decodedPath;
      })
    );
  }, [location]);

  return (
    <PageContainer className={isPageVisible ? "slide-down" : "slide-up"}>
      <Container
        style={{
          background: `url(${BackgroundImg}), #fff`,
        }}
      >
        <Content>
          {currentBrainSection && (
            <>
              <Title $theme={theme}>{currentBrainSection[0].title}</Title>
              <p>
                The prefrontal cortex is a part of the brain located in the
                frontal region of the cerebral cortex. It plays a crucial role
                in higher cognitive functions and regulates various aspects of
                cognitive activities. The main functions of the prefrontal
                cortex include:
              </p>
              <ol>
                <li>
                  <b>Planning and Executive Functions:</b> The prefrontal cortex
                  is responsible for the ability to create plans, make
                  decisions, and determine the sequence of actions to achieve a
                  goal. It helps you assess the risks and benefits of different
                  options and choose the best course of action.
                </li>
                <li>
                  <b>Attention and Concentration:</b> It regulates attention and
                  concentration on tasks and events. It helps you determine
                  where to direct your focus.
                </li>
                <li>
                  <b>Memory:</b> It affects both short-term and long-term
                  memory, contributing to the retention and retrieval of
                  information.
                </li>
                <li>
                  <b>Language and Communication:</b> The prefrontal cortex
                  assists in expressing thoughts, understanding speech, and
                  engaging in communication.
                </li>
                <li>
                  <b>Social Behavior and Self-Control:</b> It plays a crucial
                  role in controlling social behavior by regulating emotions,
                  decision-making, and interaction with others.
                </li>
                <li>
                  <b>Creativity Development:</b> The prefrontal cortex promotes
                  the generation of new ideas and creative thinkin.
                </li>
              </ol>
              <p>
                The prefrontal cortex is essential for higher cognitive
                functions and plays a key role in how we think, plan, and
                interact with the world around us. Its functions are also linked
                to various aspects of personality and social behavior.
              </p>
            </>
          )}
        </Content>
        <ImgContainer>
          <img src={BrainImg} alt="Brain Image" />
        </ImgContainer>
        <CloseButton onClick={handleExit}>
          <CloseIcon />
        </CloseButton>
      </Container>
    </PageContainer>
  );
}

const PageContainer = styled.div`
  width: 100%;
  padding: 0 100px;
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.4s ease-in-out, transform 0.5s ease-in-out;

  @media (max-width: 960px) {
    padding: 0 15px;
  }

  &.slide-down {
    opacity: 1;
    transform: translateY(0);
  }

  &.slide-up {
    opacity: 0;
    transform: translateY(-100%);
  }
`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  margin: 30px auto;
  max-width: 1162px;
  min-height: 100vh;
  max-height: 650px;
  box-shadow: 0px 14px 36px #dee4e9;
  border-radius: 51px;
  background-repeat: no-repeat !important;
  background-position: right -30px !important;

  @media (max-width: 960px) {
    flex-direction: column;
    background: radial-gradient(
        67.32% 67.32% at 50% 32.68%,
        rgba(255, 255, 255, 0) 0%,
        #ffffff 86.98%
      ),
      url(${BackgroundImg2}) !important;
    background-repeat: no-repeat !important;
    background-position: center 0px !important;
    max-height: fit-content;
    border-radius: 27px;
  }
`;

const Content = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 80px 70px;

  p,
  ol {
    font-weight: 300;
    font-size: 14px;
    line-height: 150%;
    color: #515357;
  }

  @media (max-width: 960px) {
    width: 100%;
    padding: 80px 36px 50px 36px;
    margin-top: 120px;
  }
`;

const Title = styled.h1`
  color: ${(props) => props.$theme.colors.dark};
  font-family: ${(props) => props.$theme.secondaryFont};
  font-weight: 700;
  font-size: 45px;
  line-height: 100%;
`;

const ImgContainer = styled.div`
  width: 40%;
  position: relative;
  overflow-y: hidden;
  img {
    position: absolute;
    bottom: -20px;
    right: 0px;
    border-radius: 51px;
    width: 100%;
    min-width: 400px;
    height: auto;
  }
  @media (max-width: 960px) {
    display: none;
  }
`;

const CloseButton = styled.div`
  position: absolute;
  width: 47px;
  height: 47px;
  background: #ffffff;
  box-shadow: 0px 6px 14px rgba(74, 91, 111, 0.23);
  border-radius: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 40px;
  top: 30px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  &:hover {
    box-shadow: none;
  }

  @media (max-width: 960px) {
    width: 30px;
    height: 30px;

    > svg {
      transform: scale(0.75);
    }
  }
`;

export default BrainSection;
