import React from "react";
import styled from "styled-components";
import { useTheme } from "@mui/material";

function Newsletter({ type, background }) {
  const theme = useTheme();

  return (
    <Container className="newsletter">
      <InnerContainer
        $theme={theme}
        style={{
          background: type === "img" ? `url(${background})` : background,
        }}
      >
        <Content>
          <Title $theme={theme} $type={type}>
            Stay tuned for updates!
          </Title>
          {/* <Body $theme={theme} $type={type}>
            Stay up to date with the lates changes and annoucements!
          </Body> */}
        </Content>
        {/* <TextBox $theme={theme}>
          <input type="text" placeholder="your@mail.com" />
          <Button $theme={theme}>Subscribe</Button>
        </TextBox> */}
      </InnerContainer>
    </Container>
  );
}

const Container = styled.div`
  margin: 50px 5px;

  @media (max-width: 960px) {
    margin: 0 8px 200px 8px;
  }
`;

const InnerContainer = styled.div`
  width: 100%;
  max-width: 1320px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 36px;
  box-shadow: 0px 6px 14px 0px rgba(74, 91, 111, 0.23);
  margin: 0 auto 0 auto;
  padding: 78px 85px;

  @media (max-width: 960px) {
    padding: 30px 24px;
    border-radius: 27px;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  width: 60%;

  @media (max-width: 960px) {
    width: 100%;
  }
`;

const Title = styled.h1`
  color: ${(props) =>
    props.$type === "img" ? props.$theme.colors.dark : "#f6f7f9"};
  max-width: 400px;
  font-size: 45px;
  font-weight: 800;
  line-height: 100%;
  font-family: ${(props) => props.$theme.secondaryFont};

  @media (max-width: 960px) {
    font-size: 36px;
    font-weight: 700;
    line-height: 100%;
  }
`;

const Body = styled.p`
  color: ${(props) =>
    props.$type === "img"
      ? props.$theme.colors.grey1
      : props.$theme.colors.grey2};
  font-size: 15px;
  font-weight: 400;
  line-height: 150%;
  max-width: 410px;
`;

const TextBox = styled.div`
  display: flex;
  min-width: 45%;
  flex-direction: row;
  padding: 6px 6px 6px 21px;
  justify-content: space-between;
  align-items: center;
  border-radius: 53px;
  background: #eef1f6;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25) inset, 0px 1px 2px 0px #fff;

  > input {
    width: 75%;
    color: ${(props) => props.$theme.colors.grey1};
    font-size: 15px;
    font-weight: 400;
    background: #eef1f6;
    line-height: 100%;
    letter-spacing: 1px;
    padding: 14px 0;

    &::placeholder {
      color: #9da7b1;
    }
  }
`;

const Button = styled.button`
  padding: 15px 24px;
  border-radius: 1000px;
  background: #fff;
  box-shadow: 0px 6px 14px 0px rgba(74, 91, 111, 0.23);

  color: ${(props) => props.$theme.colors.dark};
  font-size: 12px;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: 1px;

  transition: 0.3s ease-in-out all;

  &:hover {
    background: ${(props) => props.$theme.colors.dark};
    color: #f6f7f9;
  }

  &:focus {
    background: ${(props) => props.$theme.colors.dark};
    color: #f6f7f9;
  }
`;

export default Newsletter;
