import { React } from "react";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
} from "recharts";

const data = [
  {
    x: 1,
    y: 5,
  },
  {
    x: 2,
    y: 0,
  },
  {
    x: 3,
    y: 3,
  },
  {
    x: 4,
    y: 2,
  },
  {
    x: 5,
    y: -1,
  },
  {
    x: 6,
    y: 0,
  },
  {
    x: 7,
    y: 0,
  },
  {
    x: 8,
    y: 2,
  },
  {
    x: 9,
    y: 3,
  },
  {
    x: 10,
    y: 4,
  },
  {
    x: 11,
    y: 4,
  },
  {
    x: 12,
    y: 5,
  },
  {
    x: 13,
    y: 5,
  },
  {
    x: 14,
    y: 6,
  },
  {
    x: 15,
    y: 4,
  },
  {
    x: 16,
    y: 5,
  },
  {
    x: 17,
    y: 6,
  },
  {
    x: 18,
    y: 5,
  },
  {
    x: 19,
    y: 8,
  },
  {
    x: 20,
    y: 4,
  },
];

export default function ProgressLineChart() {
  return (
    <ResponsiveContainer width="100%" height={220}>
      <LineChart
        width={100}
        height={220}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: -20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis strokeWidth={0} interval={0} dataKey="x" fontSize={12} />
        <YAxis
          strokeWidth={0}
          dataKey="y"
          fontSize={12}
          tickCount={data.length}
        />
        <Line
          type="linear"
          dataKey="y"
          stroke="#AEB2BE"
          dot={{ fill: "#616779", r: 3, strokeWidth: 0 }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
}
