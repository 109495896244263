import React from "react";
import { useTheme } from "@mui/material";
import styled from "styled-components";

import { ReactComponent as PlusIcon } from "../../Icons/Plus.svg";
import { ReactComponent as MinusIcon } from "../../Icons/Minus.svg";
import { ReactComponent as CalendarIcon } from "../../Icons/Calendar.svg";
import { ReactComponent as TestIcon } from "../../Icons/Test.svg";
import { ReactComponent as LikeIcon } from "../../Icons/Like.svg";

function HomeBox({ icon, title, subtitle, text, longtext, isOpen, setIsOpen }) {
  const theme = useTheme();

  const iconComponent =
    icon === "Calendar" ? (
      <CalendarIcon />
    ) : icon === "Like" ? (
      <LikeIcon />
    ) : (
      <TestIcon />
    );

  const handleIconClick = () => {
    if (isOpen) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  };

  return (
    <StyledBox $theme={theme} $open={isOpen}>
      <Header>
        <IconContainer>{iconComponent}</IconContainer>
        <h1>{title}</h1>
      </Header>
      <Line />
      <CardBody $theme={theme} $open={isOpen}>
        <h4>{subtitle}</h4>
        <p style={{ display: isOpen ? "block" : "none" }}>{longtext}</p>
        <PlusContainer $open={isOpen} onClick={handleIconClick}>
          {isOpen ? <MinusIcon /> : <PlusIcon />}
        </PlusContainer>
        <p>{text}</p>
      </CardBody>
    </StyledBox>
  );
}

const StyledBox = styled.div`
  position: relative;
  display: inline-flex;
  padding: 15px 24px;
  height: 180px;
  width: 220px;
  row-gap: 10px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 27px;
  box-shadow: 0px 11px 20px 0px rgb(97 103 121 / 25%);
  background: #fff;

  > header h1 {
    color: ${(props) => props.$theme.colors.dark};
    font-family: ${(props) => props.$theme.secondaryFont};
    font-size: 45px;
    font-weight: 700;
    line-height: 100%;
  }

  @media (max-width: 600px) {
    height: 162px;
    width: 180px;
    gap: 15px;
  }
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  column-gap: 12px;
  margin-bottom: 5px;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  border-radius: 12px;
  background: #f6f7f9;
  box-shadow: 0px 6px 14px 0px rgba(74, 91, 111, 0.23);
`;

const CardBody = styled.div`
  margin-top: 15px;
  position: ${(props) => (props.$open ? "absolute" : "unset")};
  height: fit-content;
  border-radius: ${(props) => (props.$open ? "0 0 27px 27px" : "0")};
  z-index: ${(props) => (props.$open ? "20" : "0")};
  top: 40%;
  box-shadow: ${(props) =>
    props.$open ? "5px 15px 30px 0px rgb(97 103 121 / 25%)" : "none"};
  background: ${(props) => (props.$open ? "#fff" : "none")};
  padding: ${(props) => (props.$open ? "15px 24px" : "0")};
  width: ${(props) => (props.$open ? "220px" : "none")};
  left: 0;
  row-gap: 10px;

  > h4 {
    color: ${(props) => props.$theme.colors.grey1};
    font-size: 14px;
    font-weight: 700;
    line-height: 150%;
    margin-bottom: 5px;

    @media (max-width: 600px) {
      color: ${(props) => props.$theme.colors.grey1};
      font-size: 14px;
      font-weight: 700;
      line-height: 150%;
    }
  }

  > p {
    color: ${(props) => props.$theme.colors.grey2};
    font-size: 13px;
    font-weight: 300;
    line-height: 150%;
    width: 90%;
  }

  > p:last-child {
    @media (max-width: 600px) {
      display: none !important;
    }
  }

  @media (max-width: 600px) {
    margin-top: 5px;
    text-align: center;
    width: 100%;
    min-height: ${(props) => (props.$open ? "300px" : "none")};
    top: 50%;
  }
`;

const PlusContainer = styled.div`
  position: absolute;
  right: 2px;
  bottom: ${(props) => (props.$open ? "-2px" : "6px")};
  cursor: pointer;
  > svg {
    width: 50px;
  }
  @media (max-width: 600px) {
    left: 50%;
    right: 0;
    transform: translateX(-50%);
    bottom: ${(props) => (props.$open ? "-2px" : "-8px")};
  }
`;

const Line = styled.div`
  width: 100%;
  margin: 0 auto -5px auto !important;
  height: 1.5px;
  background-color: #e2e5e9;
  filter: drop-shadow(0px 2px 1px #fff);

  @media (max-width: 960px) {
    display: block !important;
  }
`;

export default HomeBox;
