import React from "react";
import styled from "styled-components";

const VideoModal = ({ open, onClose, children }) => {
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <ModalContainer open={open} onClick={handleOverlayClick}>
      <ModalContent>{children}</ModalContent>
    </ModalContainer>
  );
};

const ModalContainer = styled.div`
  display: ${(props) => (props.open ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
`;

const ModalContent = styled.div`
  width: 570px;
  height: auto;
  padding: 12px 12px 15px 12px;
  gap: 15px;
  border-radius: 36px;
  background: #fff;
  box-shadow: 0px 14px 36px 0px rgba(119, 126, 132, 0.82);
  z-index: 1001;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  > video {
    width: 550px;
    border-radius: 30px;
  }
`;

export default VideoModal;
