// authService.js
const TOKEN_COOKIE = "authToken";

export const authService = {
  setTokens: function (token) {
    document.cookie = `${TOKEN_COOKIE}=${token}; path=/;`;
  },
  getToken: function () {
    return this.getCookie(TOKEN_COOKIE);
  },
  logout: function () {
    document.cookie = `${TOKEN_COOKIE}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
    localStorage.removeItem("sessionInfo");
  },
  getCookie: function (name) {
    let value = "; " + document.cookie;
    let parts = value.split("; " + name + "=");
    if (parts.length === 2) return parts.pop().split(";").shift();
  },
};
