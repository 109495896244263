import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";

import { useTheme } from "@mui/material";
import { NavLink } from "react-router-dom";
import { Box, Alert, IconButton, Collapse } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import api from "../../Components/API/apiService";

import Img from "../../Images/Profile/avatar.png";
import { ReactComponent as EditIcon } from "../../Icons/Profile Icons/Edit.svg";
import { ReactComponent as InfoIcon } from "../../Icons/Profile Icons/Info.svg";
import { ReactComponent as QuestionIcon } from "../../Icons/Profile Icons/Question.svg";
import { ReactComponent as DotIcon } from "../../Icons/Profile Icons/Dot.svg";
import { ReactComponent as EmailIcon } from "../../Icons/Login_Signup/email.svg";
import { ReactComponent as EyeIcon } from "../../Icons/Login_Signup/eye.svg";
import { ReactComponent as PhoneIcon } from "../../Icons/Profile Icons/Phone.svg";

import Data from "../../Utils/ProfileQuestion.json";
import Question from "../../Components/Profile Components/Question";

function Profile({ loggedIn }) {
  const theme = useTheme();

  const [user, setUser] = useState(null);
  const [userToken, setUserToken] = useState(null);
  const [inputWidth, setInputWidth] = useState("280px");
  const [successAlertOpen, setSuccessAlertOpen] = useState(false);

  const nameFirstRef = useRef(null);
  const nameLastRef = useRef(null);
  const dateRef = useRef(null);
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const phoneRef = useRef(null);
  const saveButtonRef = useRef(null);
  const imageInputRef = useRef(null);

  const initialValues = useRef({
    nameFirst: "",
    nameLast: "",
    date: "",
    email: "",
    password: "",
    phone: "",
  });

  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await api.get("user/get");
        if (response.userID) {
          setUser(response);
          setUserToken(response.token); // Assuming the token is part of the response
          initialValues.current.nameFirst = response.firstName || "";
          initialValues.current.nameLast = response.lastName || "";
          initialValues.current.date = response.dateOfBirth || "1901-01-01";
          initialValues.current.email = response.email || "";
          initialValues.current.phone = response.phone || "";
        }
      } catch (error) {
        console.error("Error checking login status:", error);
      }
    };

    getUser();
  }, []);

  useEffect(() => {
    if (user) {
      nameFirstRef.current.value = initialValues.current.nameFirst;
      nameLastRef.current.value = initialValues.current.nameLast;
      dateRef.current.value = initialValues.current.date;
      emailRef.current.value = initialValues.current.email;
      phoneRef.current.value = initialValues.current.phone;
    }
  }, [user]);

  const saveProfileChanges = async () => {
    const newFirstName = nameFirstRef.current.value;
    const newLastName = nameLastRef.current.value;
    const newDate = dateRef.current.value;
    const newEmail = emailRef.current.value;
    const newPhone = phoneRef.current.value;

    console.log("Saving profile changes...");

    try {
      const response = await api.post(
        "user/update",
        {
          firstName: newFirstName,
          lastName: newLastName,
          dateOfBirth: newDate,
          email: newEmail,
          phone: newPhone,
        },
        {
          headers: { Authorization: `Bearer ${userToken}` },
        }
      );

      if (response.successful) {
        setSuccessAlertOpen(true);
        console.log("Profile updated successfully");
      } else {
        console.error("Error updating user data:", response);
      }
    } catch (error) {
      console.error("Error updating user data:", error);
    }
  };

  const handleEditImageClick = () => {
    imageInputRef.current.click();
  };

  const handleInputChange = () => {
    if (
      nameFirstRef.current.value !== initialValues.current.nameFirst ||
      nameLastRef.current.value !== initialValues.current.nameLast ||
      dateRef.current.value !== initialValues.current.date ||
      emailRef.current.value !== initialValues.current.email ||
      passwordRef.current.value !== initialValues.current.password ||
      phoneRef.current.value !== initialValues.current.phone
    ) {
      // saveButtonRef.current.disabled = false;
    } else {
      // saveButtonRef.current.disabled = true;
    }
  };

  useEffect(() => {
    if (saveButtonRef.current) {
      // saveButtonRef.current.disabled = true;
    }

    if (nameFirstRef.current) {
      nameFirstRef.current.addEventListener("input", handleInputChange);
    }
    if (nameLastRef.current) {
      nameLastRef.current.addEventListener("input", handleInputChange);
    }
    if (dateRef.current) {
      dateRef.current.addEventListener("input", handleInputChange);
    }
    if (emailRef.current) {
      emailRef.current.addEventListener("input", handleInputChange);
    }
    if (passwordRef.current) {
      passwordRef.current.addEventListener("input", handleInputChange);
    }
    if (phoneRef.current) {
      phoneRef.current.addEventListener("input", handleInputChange);
    }

    return () => {
      if (nameFirstRef.current) {
        nameFirstRef.current.removeEventListener("input", handleInputChange);
      }
      if (nameLastRef.current) {
        nameLastRef.current.removeEventListener("input", handleInputChange);
      }
      if (dateRef.current) {
        dateRef.current.removeEventListener("input", handleInputChange);
      }
      if (emailRef.current) {
        emailRef.current.removeEventListener("input", handleInputChange);
      }
      if (passwordRef.current) {
        passwordRef.current.removeEventListener("input", handleInputChange);
      }
      if (phoneRef.current) {
        phoneRef.current.removeEventListener("input", handleInputChange);
      }
      if (saveButtonRef.current) {
        saveButtonRef.current.removeEventListener("click", saveProfileChanges);
      }
    };
  }, []);

  return (
    <>
      <Container>
        <InnerContainer>
          <UserInfo>
            <ImgContainer>
              <img src={Img} alt="Avatar" />
              <div onClick={handleEditImageClick}>
                <EditIcon />
              </div>
            </ImgContainer>
            <input
              type="file"
              ref={imageInputRef}
              style={{ display: "none" }}
              onChange={(e) => {
                console.log(e.target.files[0]);
              }}
            />
            <Content>
              <InputContainer $theme={theme}>
                <input
                  type="text"
                  ref={nameFirstRef}
                  style={{
                    width: inputWidth,
                  }}
                  placeholder="First Name"
                />
              </InputContainer>
              <InputContainer $theme={theme}>
                <input
                  type="text"
                  ref={nameLastRef}
                  style={{
                    width: inputWidth,
                  }}
                  placeholder="Last Name"
                />
              </InputContainer>
              <Date $theme={theme}>
                <input type="date" ref={dateRef} placeholder="" />
              </Date>
              <InputContainer $theme={theme}>
                <input
                  type="email"
                  ref={emailRef}
                  placeholder="your@mail.com"
                />
                <EmailIcon />
              </InputContainer>
              <InputContainer $theme={theme}>
                <input
                  type="password"
                  ref={passwordRef}
                  placeholder="New Password"
                />
                <EyeIcon />
              </InputContainer>
              <InputContainer $theme={theme}>
                <input type="tel" ref={phoneRef} placeholder="000-000-0000" />
                <PhoneIcon />
              </InputContainer>
              <ButtonDark
                style={{ marginTop: "15px" }}
                ref={saveButtonRef}
                $theme={theme}
                onClick={saveProfileChanges} // Ensure this is correctly set
              >
                Save Changes
              </ButtonDark>
            </Content>
          </UserInfo>
          <LineMobile />
          {/* <Description $theme={theme}>
            <InfoIcon />
            <p>
              [Brief description of what these questions are for], you agree to
              our <span>Terms and Conditions</span>. Also, read our{" "}
              <span>policies</span>. We use your data to offer you a
              personalized experience and to better understand and improve our
              services. For more information see{" "}
              <span>
                <a>here</a>
              </span>
              .
            </p>
          </Description>
          <Questions>
            {Data.map((question, i) => (
              <Question
                key={i}
                id={i + 1}
                title={question.title}
                number={i + 1}
                options={question.options}
                type={question.type}
                onSave={onSave}
                setOnSave={setOnSave}
                setAnswers={setAnswers}
              />
            ))}
          </Questions>
          <ButtonDark
            style={{ margin: "50px 0", float: "right" }}
            $theme={theme}
            onClick={savePreferences}
          >
            Save Changes
          </ButtonDark> */}
        </InnerContainer>
      </Container>
      <Line />
      {/* <Footer $theme={theme}>
        <p>
          <NavLink to="/terms">Terms And Conditions</NavLink>
          <DotIcon />
          <NavLink to="/terms"> Policies</NavLink>
        </p>
        <SupportButton $theme={theme}>
          <div>
            <QuestionIcon />
          </div>
          <NavLink to="/support"> Support</NavLink>
        </SupportButton>
      </Footer> */}
      <Box
        sx={{
          position: "fixed",
          bottom: "16px",
          left: "16px",
          width: "300px",
          zIndex: 9999,
        }}
      >
        <Collapse in={successAlertOpen}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setSuccessAlertOpen(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{
              width: "100%",
            }}
          >
            User data updated successfully!
          </Alert>
        </Collapse>
      </Box>
    </>
  );
}

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: relative;
  @media (min-width: 2000px) {
    justify-content: center;
  }
`;

const InnerContainer = styled.div`
  width: 80%;
  padding: 40px 100px;
  @media (max-width: 960px) {
    width: 100%;
    padding: 40px 0px;
    > button {
      width: 95%;
      margin: 50px 10px !important;
      float: unset !important;
    }
  }
`;

const ImgContainer = styled.div`
  position: relative;
  height: 305px;
  @media (max-width: 960px) {
    height: 250px;
  }
  > img {
    width: 257px;
    height: 305px;
    flex-shrink: 0;
    border-radius: 36px;
    object-fit: cover;
    box-shadow: 0px 6px 14px 0px rgba(74, 91, 111, 0.23);
    @media (max-width: 960px) {
      width: 200px;
      height: 250px;
    }
  }
  > div {
    display: inline-flex;
    padding: 9px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 9px;
    background: #fff;
    box-shadow: -6px 15px 35px 0px rgba(97, 103, 121, 0.11),
      -29px 31px 41px 0px rgba(174, 178, 190, 0.22);
    position: absolute;
    left: 50%;
    bottom: -15px;
    transform: translateX(-50%);
    cursor: pointer;

    @media (max-width: 960px) {
      left: 100%;
      bottom: 50%;
      transform: translateX(-50%) translateY(50%);
    }
  }
`;

const UserInfo = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 40px;
  > div svg {
    cursor: pointer;
  }

  @media (max-width: 960px) {
    flex-direction: column;
    align-items: center;
    row-gap: 40px;
    padding: 0px 15px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  width: -webkit-fill-available;

  @media (max-width: 960px) {
    align-items: center;

    > button {
      width: 100%;
    }
  }
`;

const Title = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 10px;

  > input {
    border: 1px solid transparent;
    background: transparent;
    color: ${(props) => props.$theme.colors.dark};
    font-size: 36px;
    font-weight: 700;
    line-height: 100%;
    font-family: ${(props) => props.$theme.secondaryFont};
    max-width: 320px;

    &:focus {
      border: 1px solid #202228;
    }

    &::placeholder {
      color: ${(props) => props.$theme.colors.dark};
    }
  }

  @media (max-width: 960px) {
    font-size: 30px;
    font-weight: 700;
    line-height: 100%;
  }
`;

const Date = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 10px;

  > input {
    background: transparent;
    color: ${(props) => props.$theme.colors.dark};
    font-family: ${(props) => props.$theme.secondaryFont};
    font-size: 18px;
    font-weight: 500;
    line-height: 140%;
    width: 135px;
    cursor: text;
    &::placeholder {
      color: ${(props) => props.$theme.colors.dark};
    }
  }
  > svg {
    width: 20px;
    margin-top: -1px;
  }
`;

const InputContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 2px 21px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 53px;
  background: #eef1f6;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25) inset, 0px 1px 2px 0px #fff;

  &.error {
    border-color: #ff292e;
  }

  > input {
    background: #eef1f6;
    min-width: 90%;
    color: ${(props) => props.$theme.colors.dark};
    font-size: 15px;
    padding: 10px 0;

    &::placeholder {
      color: ${(props) => props.$theme.colors.dark};
      font-size: 14px;
      font-weight: 400;
      line-height: 100%;
      letter-spacing: 1px;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ButtonDark = styled.button`
  width: 140px;
  align-self: flex-end;
  background-color: ${(props) => props.$theme.colors.dark};
  color: ${(props) => props.$theme.colors.white};
  display: inline-flex;
  padding: 15px 24px;
  justify-content: center;
  align-items: center;
  gap: 30px;
  border-radius: 1000px;
  box-shadow: 0px 6px 14px 0px rgba(74, 91, 111, 0.23);
  font-size: 12px;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: 1px;
  &:disabled {
    opacity: 0.3;
    cursor: default;
  }
`;

const Description = styled.div`
  max-width: 600px;
  display: flex;
  align-items: flex-start;
  gap: 12px;
  color: ${(props) => props.$theme.colors.grey1};
  font-size: 12px;
  font-weight: 300;
  line-height: 150%;
  margin: 80px 0 40px 0;

  > p span {
    font-weight: 600;
    text-decoration-line: underline;
  }

  > svg {
    width: 20px;
    height: 19px;
    flex-shrink: 0;
  }
  @media (max-width: 960px) {
    margin: 20px 15px 40px 15px;
  }
`;

const Questions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 36px;
  width: 100%;
`;

const Line = styled.div`
  width: 80%;
  margin: 0px auto;
  height: 1.5px;
  float: right;
  background-color: #e2e5e9;
  filter: drop-shadow(0px 2px 1px #fff);

  @media (max-width: 960px) {
    display: none;
  }
`;

const LineMobile = styled.div`
  width: 100%;
  margin: 35px auto 25px auto;
  height: 1.5px;
  background-color: #e2e5e9;
  filter: drop-shadow(0px 2px 1px #fff);

  @media (min-width: 960px) {
    display: none;
  }
`;

const Footer = styled.div`
  width: 80%;
  @media (max-width: 960px) {
    width: 100%;
    display: inline-flex;
    padding: 81px 15px 36px 15px;
    align-items: center;
  }
  padding: 50px 100px;
  float: right;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > p {
    color: ${(props) => props.$theme.colors.dark};
    font-size: 15px;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 1px;
    display: flex;
    align-items: center;

    > a {
      transition: 0.3s ease-in-out;
      &:hover {
        opacity: 0.5;
      }
    }

    > svg {
      margin: 5px 15px 0 15px;
    }
  }
`;

const SupportButton = styled.div`
  display: flex;
  align-items: center;

  > div {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    flex-shrink: 0;
    border-radius: 47.442px;
    background: #fff;
    box-shadow: 0px 5.581px 13.953px 0px rgba(74, 91, 111, 0.23);
  }

  > a {
    transition: 0.3s ease-in-out;
    color: ${(props) => props.$theme.colors.dark};
    font-size: 15px;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 1px;
    display: flex;
    align-items: center;

    &:hover {
      opacity: 0.5;
    }
  }
`;

export default Profile;
