import { React, useEffect } from "react";
import styled from "styled-components";

import {
  Radio,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  useTheme,
} from "@mui/material";

function Option({
  selectedOptions,
  setSelectedOptions,
  option,
  options,
  optionIndex,
  optionsLength,
  type,
}) {
  const theme = useTheme();
  const handleRadioChange = (optionIndex, value) => {
    if (type === "check") {
      const updatedOptions = [...selectedOptions];
      const updatedOption = { ...updatedOptions[optionIndex] };
      const valueIndex = updatedOption.selected.indexOf(value);

      if (valueIndex === -1) {
        updatedOption.selected.push(value);
      } else {
        updatedOption.selected.splice(valueIndex, 1);
      }

      updatedOptions[optionIndex] = updatedOption;
      setSelectedOptions(updatedOptions);
    } else if (type === "mcq") {
      const updatedOptions = [...selectedOptions];
      updatedOptions[optionIndex] = {
        ...updatedOptions[optionIndex],
        selected: value,
      };
      setSelectedOptions(updatedOptions);
    }
  };

  const handleTextChange = (e) => {
    const updatedOptions = [...selectedOptions];
    updatedOptions[optionIndex] = {
      ...updatedOptions[optionIndex],
      selected: e.target.value,
    };
    setSelectedOptions(updatedOptions);
  };

  useEffect(() => {
    if (type === "check") {
      setSelectedOptions(
        options.map((element) => ({ title: element.title, selected: [] }))
      );
    }
  }, []);

  return (
    <Container $theme={theme}>
      <h4>{option.title} :</h4>
      {type === "check" || type === "mcq" ? (
        <RadioGroup
          row
          onChange={(event) =>
            handleRadioChange(optionIndex, event.target.value)
          }
          sx={{
            columnGap: "20px",
          }}
        >
          {Array.isArray(option.values) &&
            option.values.map((value, i) => (
              <FormControlLabel
                key={i}
                value={value}
                control={type === "mcq" ? <Radio /> : <Checkbox />}
                onChange={
                  type !== "mcq"
                    ? (event) =>
                        handleRadioChange(optionIndex, event.target.value)
                    : null
                }
                label={value}
                sx={{
                  "& .MuiFormControlLabel-label": {
                    color: "#515357",
                    fontSize: "14px",
                    fontWeight: 300,
                    lineHeight: "150%",
                    marginLeft: "5px",
                  },
                  "& span span svg": {
                    background: "#EDEFF3",
                    width: "24px",
                    height: "24px",
                    borderRadius: "53px",
                    boxShadow:
                      "0px 2px 3px 0px rgba(0, 0, 0, 0.31) inset, 0px 3px 1px 0px #FFF",
                  },
                  "& span svg": {
                    background: "#EDEFF3",
                    width: "24px",
                    height: "24px",
                    borderRadius: "6px",
                    boxShadow:
                      "0px 2px 3px 0px rgba(0, 0, 0, 0.31) inset, 0px 3px 1px 0px #FFF",
                  },
                  "& span span svg path": {
                    display:
                      selectedOptions[optionIndex]?.selected === value
                        ? "block"
                        : "none",
                    fill: "#39b3c9",
                    width: "14px",
                    height: "14px",
                  },
                  "& span svg path": {
                    display:
                      type === "check" &&
                      selectedOptions[optionIndex].selected?.includes(value)
                        ? "block"
                        : "none",
                    fill: "#279bb0",
                  },
                }}
              />
            ))}
        </RadioGroup>
      ) : (
        <TextBox
          type="text"
          onChange={handleTextChange}
          placeholder="Enter Answer in Numbers ..."
        ></TextBox>
      )}
      {optionIndex < optionsLength - 1 ? <Line /> : null}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  width: 100%;
  > h4 {
    color: ${(props) => props.$theme.colors.grey1};
    font-family: ${(props) => props.$theme.secondaryFont};
    font-size: 18px;
    font-weight: 500;
    line-height: 140%;
    margin-bottom: -10px;
    @media (max-width: 960px) {
      font-weight: 800;
    }
  }
`;

const Line = styled.div`
  width: 100%;
  height: 1.5px;
  background-color: #e2e5e9;
  filter: drop-shadow(0px 2px 1px #fff);
`;

const TextBox = styled.input`
  color: #515357;
  width: 250px;
  border-radius: 53px;
  margin-top: 15px;
  padding: 15px 21px;
  background: #eef1f6;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25) inset, 0px 1px 2px 0px #fff;

  &::placeholder {
    color: #9da7b1;
    font-size: 14px;
    font-weight: 100;
    line-height: 100%;
    font-style: italic;
    letter-spacing: 1px;
  }

  @media (max-width: 960px) {
    width: 100%;
  }
`;

export default Option;
