import { useEffect } from "react";
import { React, useState } from "react";

import BrainImg2 from "../../../../Images/Profile/Brain2.png";
import BrainImg3 from "../../../../Images/Profile/Brain3.png";

const Brain = () => {
  const [currentImage, setCurrentImage] = useState(BrainImg2);
  const [currentSection, setCurrentSection] = useState("");

  const handleMouseMove = (e) => {
    // Handle mouse move logic here
    //console.log("Mouse move:", e);
  };

  useEffect(() => {
    if (currentSection === "PL" || currentSection === "PLNew") {
      setCurrentImage(BrainImg2);
    } else if (currentSection === "PC" || currentSection === "PCNew") {
      setCurrentImage(BrainImg3);
    }
  }, [currentSection]);

  return (
    <div style={{width: "50%"}}>
      <img
        src={currentImage}
        alt="Brain Diagram"
        useMap="#brainMap"
        style={{ width: "400px", height: "auto" }}
      />
      <map name="brainMap">
        <area
          alt="Paritetal Lobes"
          title="Paritetal Lobes"
          coords="252,3,249,22,241,49,243,72,245,90,253,105,260,112,278,125,298,136,317,140,342,147,353,143,356,130,353,110,345,88,331,65,318,41,299,20,277,8"
          shape="poly"
          onMouseOver={() => handleMouseMove("Paritetal Lobe")}
          onClick={() =>
            setCurrentSection(currentSection === "PL" ? "PLNew" : "PL")
          }
          style={{ cursor: "pointer" }}
        />
        <area
          alt="Prefrontal Cortex"
          title="Prefrontal Cortex"
          coords="94,45,101,72,109,96,114,112,118,127,123,146,136,155,138,171,142,189,133,209,108,210,93,209,79,206,54,198,46,178,44,163,45,137,51,108,63,67"
          shape="poly"
          onMouseOver={() => handleMouseMove("Prefrontal Cortex")}
          onClick={() =>
            setCurrentSection(currentSection === "PC" ? "PCNew" : "PC")
          }
          style={{ cursor: "pointer" }}
        />
      </map>
    </div>
  );
};

export default Brain;
