import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import api from "../../Components/API/apiService";
import Instructions from "./Instructions";
import AssessmentInstruction from "./AssessmentInstruction";
import { TailSpin } from "react-loader-spinner";

function AssessmentTemplate() {
  const { userAssessmentID } = useParams();
  const [assessment, setAssessment] = useState(null);
  const [instructionsAllowed, setInstructionsAllowed] = useState(null);
  const [showInstructions, setShowInstructions] = useState(null);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (userAssessmentID) {
      const fetchAssessmentQuestions = async () => {
        try {
          const response = await api.get(`assessment/get/${userAssessmentID}`);
          console.log(response);
          if (response.userAssessmentID) {
            setAssessment(response);
            setInstructionsAllowed(response.user.showIntro);
          } else {
            console.error("Failed to fetch assessment data");
          }
        } catch (error) {
          console.error("Error fetching assessment data:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchAssessmentQuestions();
    }
  }, [userAssessmentID]);

  useEffect(() => {
    if (!loading && assessment) {
      const isMatchingPath =
        location.pathname === "/assessment/instructions" ||
        location.pathname === "/assessment/instructions/";

      if (!isMatchingPath && instructionsAllowed) {
        setShowInstructions(true);
      } else if (!isMatchingPath && !instructionsAllowed) {
        navigate(`/assessment/instructions/${userAssessmentID}`);
        setShowInstructions(false);
      } else {
        setShowInstructions(false);
      }
    }
  }, [location.pathname, loading, instructionsAllowed, navigate, assessment]);

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "95vh",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <TailSpin
            visible={true}
            height="80"
            width="80"
            color="#198AA9"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      ) : (
        assessment &&
        showInstructions !== null &&
        (showInstructions ? (
          <Instructions />
        ) : (
          <AssessmentInstruction
            title={assessment.title || "No title available"}
            description={assessment.description || "No description available"}
          />
        ))
      )}
    </>
  );
}

export default AssessmentTemplate;
