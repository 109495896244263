// Helper function to convert milliseconds to minutes
const convertToMinutes = (milliseconds) => {
  return (milliseconds / 60000).toFixed(2);
};

// Helper function to format date with suffix
const formatDateWithSuffix = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "long" });
  const year = date.getFullYear();

  // Determine the correct suffix
  let daySuffix;
  if (day % 10 === 1 && day !== 11) {
    daySuffix = "st";
  } else if (day % 10 === 2 && day !== 12) {
    daySuffix = "nd";
  } else if (day % 10 === 3 && day !== 13) {
    daySuffix = "rd";
  } else {
    daySuffix = "th";
  }

  return `${month} ${day}${daySuffix}, ${year}`;
};

module.exports = {
  convertToMinutes,
  formatDateWithSuffix,
};
