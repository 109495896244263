import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTheme } from "@mui/material";
import styled from "styled-components";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Question from "./Question";
import api from "../../Components/API/apiService";
import { NavLink, useNavigate } from "react-router-dom";

function QuestionTemplate() {
  const [assessment, setAssessment] = useState(null);
  const [index, setIndex] = useState(0);
  const { userAssessmentID } = useParams();
  const navigate = useNavigate();

  const theme = useTheme();

  useEffect(() => {
    const fetchAssessmentQuestions = async () => {
      try {
        const response = await api.get(`assessment/get/${userAssessmentID}`); // Replace with dynamic assessment ID if needed
        if (response && response.userAssessmentID) {
          setAssessment(response);
        } else {
          console.error("Failed to fetch assessment data");
        }
      } catch (error) {
        console.error("Error fetching assessment data:", error);
      }
    };

    fetchAssessmentQuestions();
  }, []);

  const handleNextQuestion = () => {
    setIndex((prevIndex) => prevIndex + 1);
  };

  return (
    <Container>
      <Header>
        <NavigationButton $theme={theme}>
          <NavIcon onClick={() => navigate("/profile/overview")}>
            <ChevronLeftIcon />
          </NavIcon>
          Home
        </NavigationButton>
        <ProgressBar>
          <InnerBar
            $current={
              assessment ? assessment.userAssessmentQuestions[index].order : 0
            }
            $total={assessment ? assessment.numberOfQuestions : 1}
          ></InnerBar>
        </ProgressBar>
      </Header>

      <InnerContainer>
        {assessment && assessment.userAssessmentQuestions[index] && (
          <Question
            questionData={assessment.userAssessmentQuestions[index]}
            handleNextQuestion={handleNextQuestion}
            totalQuestions={assessment.numberOfQuestions}
            currentQuestionIndex={index}
          />
        )}
      </InnerContainer>
    </Container>
  );
}

const Container = styled.div`
  padding: 0px 60px;
  display: flex;
  flex-direction: column;

  @media (max-width: 960px) {
    padding: 0px 15px;
  }
`;

const Header = styled.div`
  display: flex;
  padding: 40px 0px 50px 0;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 960px) {
    padding: 30px 0px 40px 0px;
  }
`;

const NavigationButton = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 12px;
  color: ${(props) => props.$theme.colors.dark};
  font-size: 15px;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: 1px;

  @media (max-width: 960px) {
    span.mobile_remove {
      display: none;
    }
    span {
      color: ${(props) => props.$theme.colors.grey2};
    }
  }
`;

const NavIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 47px;
  min-height: 47px;
  box-shadow: 0px 4px 10px 1px #d8d8d8;
  cursor: pointer;
  background-color: #fff;
  border-radius: 50%;
  transition: 0.2s ease-in-out;
  > svg {
    width: 25px;
  }

  &:hover {
    box-shadow: none;
  }
`;

const ProgressBar = styled.div`
  display: flex;
  height: 6px;
  margin-left: 60px;
  align-items: center;
  flex: 1 0 0;
  border-radius: 53px;
  background: #eef1f6;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25) inset, 0px 1px 2px 0px #fff;

  @media (max-width: 960px) {
    margin-left: 30px;
  }
`;

const InnerBar = styled.div`
  width: ${(props) => (props.$current / props.$total) * 100}%;
  height: 6px;
  border-radius: 53px;
  background: #56c5e8;
  transition: width 0.3s ease-in-out;
`;

const InnerContainer = styled.div`
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 50px;

  @media (max-width: 960px) {
    width: 100%;
  }
`;

export default QuestionTemplate;
