import React from "react";
import styled from "styled-components";
import { useTheme } from "@mui/material";

function HamburgerMenu({ menuOpen, setMenuOpen }) {
  const theme = useTheme();
  return (
    <>
      <Menu
        $open={menuOpen}
        $theme={theme}
        onClick={() => setMenuOpen(!menuOpen)}
      >
        <div className="line-1"></div>
        <div className="line-2"></div>
        <div className="line-3"></div>
      </Menu>
    </>
  );
}

const Menu = styled.div`
  width: 40px;
  display: flex;
  flex-direction: column;
  row-gap: 7px;
  z-index: 1300;
  position: absolute;
  cursor: pointer;
  > div {
    width: 100%;
    height: 3px;
    background-color: ${(props) => props.$theme.colors.dark};
    border-radius: 4px;
    transition: all 0.4s ease-in-out;
  }
  > .line-1 {
    transform: ${(props) =>
      props.$open ? "translateY(5px) rotate(45deg)" : "rotate(0deg)"};
    transform-origin: center;
  }
  > .line-2 {
    transform: ${(props) =>
      props.$open ? "translateY(-5px) rotate(-45deg)" : "rotate(0deg)"};
  }
  > .line-3 {
    width: ${(props) => (props.$open ? "60%" : "60%")};
    opacity: ${(props) => (props.$open ? "0" : "1")};
  }
`;

export default HamburgerMenu;
