import React from "react";
import { PieChart, Pie } from "recharts";
import styled from "styled-components";
import { useTheme } from "@mui/material";

function ProgressPie({ showInfo }) {
  const theme = useTheme();
  // chart 1 data (pie chart, value is in %)
  const chart1_Data = [{ id: "1", name: "Data_1", value: 75 }];

  return (
    <Container>
      <div>
        <PieChart width={150} height={150}>
          <defs>
            <linearGradient id="color1" x1="1" y1="1" x2="0" y2="0">
              <stop offset="0%" stopColor="#6DD4F0" stopOpacity={1} />
              <stop offset="50%" stopColor="#81d9f2" stopOpacity={1} />
              <stop offset="100%" stopColor="#cbf4ff" stopOpacity={1} />
            </linearGradient>
          </defs>
          <Pie
            data={chart1_Data}
            dataKey="value"
            innerRadius="80%"
            outerRadius="100%"
            fill="#E9EDF1"
            isAnimationActive={false}
          ></Pie>
          <Pie
            data={chart1_Data}
            dataKey="value"
            innerRadius="80%"
            outerRadius="100%"
            fill="url(#color1)"
            startAngle={90}
            endAngle={(chart1_Data[0].value / 100) * 360 + 90}
            cornerRadius="100"
          ></Pie>
        </PieChart>
        <ChartLabel $theme={theme}>
          <p>
            {chart1_Data[0].value} <span>%</span>
          </p>
          <p>Percent Correct</p>
        </ChartLabel>
      </div>
      {showInfo && (
        <Info $theme={theme}>
          <div>
            <h6>Correct Answers : </h6>
            <p>
              22 <span>/ 29</span>
            </p>
          </div>
          <div>
            <h6>Average Answer Time : </h6>
            <p>
              120 <span>/ sec</span>
            </p>
          </div>
        </Info>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 100%;

  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

const ChartLabel = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 34%;
  left: 16%;

  > p {
    color: ${(props) => props.$theme.colors.dark};
    font-family: ${(props) => props.$theme.secondaryFont};
    font-size: 42px;
    font-weight: 700;
    line-height: 100%;

    > span {
      color: ${(props) => props.$theme.colors.grey2};
      font-size: 17px;
      font-weight: 400;
      line-height: 100%;
      letter-spacing: 1px;
      margin-left: -8px;
    }
  }

  > p:last-child {
    font-size: 10px;
    font-weight: 400;
    line-height: 110%;
    margin-left: -8px;
  }

  @media (max-width: 960px) {
    top: 20%;
    left: 42%;
  }

  @media (max-width: 600px) {
    top: 20%;
    left: 37%;
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  > div {
    display: flex;
    flex-direction: column;
    gap: 5px;

    > h6 {
      color: ${(props) => props.$theme.colors.grey1};
      font-size: 12px;
      font-weight: 300;
      line-height: 150%;
    }

    > p {
      font-size: 14px;
      font-weight: 700;
      line-height: 150%;

      > span {
        color: ${(props) => props.$theme.colors.grey2};
        font-weight: 300;
      }
    }
  }
`;

export default ProgressPie;
