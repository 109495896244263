import React, { useState, useRef } from "react";
import styled from "styled-components";
import { useTheme } from "@mui/material";
import { NavLink } from "react-router-dom";

import Newsletter from "../Components/Profile Components/PageComponents/Newsletter";
import NewsletterImg from "../Images/Pages/Newsletter.png";
import { ReactComponent as SearchIcon } from "../Icons/Pages/search.svg";

function Terms() {
  const theme = useTheme();

  return (
    <Container>
      <>
        <Header $theme={theme}>
          <h6>Legal</h6>
          <Title $theme={theme}>Terms of Service</Title>
          <Links $theme={theme}>
            <NavLink to="/">Home</NavLink> /<NavLink to="/terms">Legal</NavLink>
          </Links>
        </Header>
        <BottomBar>
          <BottomLinks>
            <a className="active">Terms</a>
            <a>Policies</a>
          </BottomLinks>
        </BottomBar>
      </>
      <ContentContainer>
        <Search>
          <input type="text" placeholder="Search ..." />
          <SearchIcon />
        </Search>
        <Content>
          <TableOfContents $theme={theme}>
            <div>
              <h3>Section 1</h3>
              <h2><a href="#">Title Of Section</a></h2>
              <h4>
                <span>1</span> Information
              </h4>
              <ul>
                <li>
                  <a href="#">Paragraph_1</a>
                </li>
                <li>
                  <a href="#">Paragraph_2</a>
                </li>
                <li>
                  <a href="#">Paragraph_3</a>
                </li>
                <li>
                  <a href="#">Paragraph_4</a>
                </li>
              </ul>
            </div>
            <div>
              <h3>Section 1</h3>
              <h2><a href="#">Title Of Section</a></h2>
              <h4>
                <span>1</span> Information
              </h4>
              <ul>
                <li>
                  <a href="#">Paragraph_1</a>
                </li>
                <li>
                  <a href="#">Paragraph_2</a>
                </li>
                <li>
                  <a href="#">Paragraph_3</a>
                </li>
                <li>
                  <a href="#">Paragraph_4</a>
                </li>
              </ul>
            </div>
            <div>
              <h3>Section 1</h3>
              <h2><a href="#">Title Of Section</a></h2>
              <h4>
                <span>1</span> Information
              </h4>
              <ul>
                <li>
                  <a href="#">Paragraph_1</a>
                </li>
                <li>
                  <a href="#">Paragraph_2</a>
                </li>
                <li>
                  <a href="#">Paragraph_3</a>
                </li>
                <li>
                  <a href="#">Paragraph_4</a>
                </li>
              </ul>
            </div>
          </TableOfContents>
          <ContentText $theme={theme}>
            <h2>Title Of Section</h2>
            <h4>Subtitle</h4>
            <p>
              Norem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu
              turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus
              nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum
              tellus elit sed risus. Maecenas eget condimentum velit, sit amet
              feugiat lectus. Class aptent taciti sociosqu ad litora torquent
              per conubia nostra, per inceptos himenaeos. Praesent auctor purus
              luctus enim egestas, ac scelerisque ante pulvinar. Donec ut
              rhoncus ex. Suspendisse ac rhoncus nisl, eu tempor urna. Curabitur
              vel bibendum lorem. Morbi convallis convallis diam sit amet
              lacinia. Aliquam in elementum tellus.<br></br> Curabitur tempor
              quis eros tempus lacinia. Nam bibendum pellentesque quam a
              convallis. Sed ut vulputate nisi. Integer in felis sed leo
              vestibulum venenatis. Suspendisse quis arcu sem. Aenean feugiat ex
              eu vestibulum vestibulum. Morbi a eleifend magna. Nam metus lacus,
              porttitor eu mauris a, blandit ultrices nibh. Mauris sit amet
              magna non ligula vestibulum eleifend. Nulla varius volutpat turpis
              sed lacinia. Nam eget mi in purus lobortis eleifend. Sed nec ante
              dictum sem condimentum ullamcorper quis venenatis nisi. Proin
              vitae facilisis nisi, ac posuere leo.<br></br> Nam pulvinar
              blandit velit, id condimentum diam faucibus at. Aliquam lacus
              nisi, sollicitudin at nisi nec, fermentum congue felis. Quisque
              mauris dolor, fringilla sed tincidunt ac, finibus non odio. Sed
              vitae mauris nec ante pretium finibus. Donec nisl neque, pharetra
              ac elit eu, faucibus aliquam ligula. Nullam dictum, tellus
              tincidunt tempor laoreet, nibh elit sollicitudin felis, eget
              feugiat sapien diam nec nisl. Aenean gravida turpis nisi,
              consequat dictum risus dapibus a. Duis felis ante, varius in neque
              eu, tempor suscipit sem. Maecenas ullamcorper gravida sem sit amet
              cursus. Etiam pulvinar purus vitae justo pharetra consequat.
              Mauris id mi ut arcu feugiat maximus. Mauris consequat tellus id
              tempus aliquet.<br></br> Vestibulum dictum ultrices elit a luctus.
              Sed in ante ut leo congue posuere at sit amet ligula. Pellentesque
              eget augue nec nisl sodales blandit sed et sem. Aenean quis
              finibus arcu, in hendrerit purus. Praesent ac aliquet lorem. Morbi
              feugiat aliquam ligula, et vestibulum ligula hendrerit vitae. Sed
              ex lorem, pulvinar sed auctor sit amet, molestie a nibh. Ut
              euismod nisl arcu, sed placerat nulla volutpat aliquet. Ut id
              convallis nisl. Ut mauris leo, lacinia sed elit id, sagittis
              rhoncus odio. Pellentesque sapien libero, lobortis a placerat et,
              malesuada sit amet dui. Nam sem sapien, congue eu rutrum nec,
              pellentesque eget ligula.
            </p>
            <h4>Subtitle</h4>
            <p>
              Norem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu
              turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus
              nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum
              tellus elit sed risus. Maecenas eget condimentum velit, sit amet
              feugiat lectus. Class aptent taciti sociosqu ad litora torquent
              per conubia nostra, per inceptos himenaeos. Praesent auctor purus
              luctus enim egestas, ac scelerisque ante pulvinar. Donec ut
              rhoncus ex. Suspendisse ac rhoncus nisl, eu tempor urna. Curabitur
              vel bibendum lorem. Morbi convallis convallis diam sit amet
              lacinia. Aliquam in elementum tellus.<br></br> Curabitur tempor
              quis eros tempus lacinia. Nam bibendum pellentesque quam a
              convallis. Sed ut vulputate nisi. Integer in felis sed leo
              vestibulum venenatis. Suspendisse quis arcu sem. Aenean feugiat ex
              eu vestibulum vestibulum. Morbi a eleifend magna. Nam metus lacus,
              porttitor eu mauris a, blandit ultrices nibh. Mauris sit amet
              magna non ligula vestibulum eleifend. Nulla varius volutpat turpis
              sed lacinia. Nam eget mi in purus lobortis eleifend. Sed nec ante
              dictum sem condimentum ullamcorper quis venenatis nisi. Proin
              vitae facilisis nisi, ac posuere leo.<br></br> Nam pulvinar
              blandit velit, id condimentum diam faucibus at. Aliquam lacus
              nisi, sollicitudin at nisi nec, fermentum congue felis. Quisque
              mauris dolor, fringilla sed tincidunt ac, finibus non odio. Sed
              vitae mauris nec ante pretium finibus. Donec nisl neque, pharetra
              ac elit eu, faucibus aliquam ligula. Nullam dictum, tellus
              tincidunt tempor laoreet, nibh elit sollicitudin felis, eget
              feugiat sapien diam nec nisl. Aenean gravida turpis nisi,
              consequat dictum risus dapibus a. Duis felis ante, varius in neque
              eu, tempor suscipit sem. Maecenas ullamcorper gravida sem sit amet
              cursus. Etiam pulvinar purus vitae justo pharetra consequat.
              Mauris id mi ut arcu feugiat maximus. Mauris consequat tellus id
              tempus aliquet.<br></br> Vestibulum dictum ultrices elit a luctus.
              Sed in ante ut leo congue posuere at sit amet ligula. Pellentesque
              eget augue nec nisl sodales blandit sed et sem. Aenean quis
              finibus arcu, in hendrerit purus. Praesent ac aliquet lorem. Morbi
              feugiat aliquam ligula, et vestibulum ligula hendrerit vitae. Sed
              ex lorem, pulvinar sed auctor sit amet, molestie a nibh. Ut
              euismod nisl arcu, sed placerat nulla volutpat aliquet. Ut id
              convallis nisl. Ut mauris leo, lacinia sed elit id, sagittis
              rhoncus odio. Pellentesque sapien libero, lobortis a placerat et,
              malesuada sit amet dui. Nam sem sapien, congue eu rutrum nec,
              pellentesque eget ligula.
            </p>
          </ContentText>
        </Content>
      </ContentContainer>
      <Newsletter type="img" background={NewsletterImg} />
    </Container>
  );
}

const Container = styled.div``;
const Header = styled.div`
  display: flex;
  max-width: 1440px;
  padding: 30px 0px 20px 120px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 6px;
  margin: 20px auto 0px auto;

  > h6 {
    color: ${(props) => props.$theme.colors.grey2};
    font-size: 18px;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 1px;
  }

  @media (max-width: 960px) {
    padding: 30px 15px 50px 15px;
    flex-direction: column-reverse;
    gap: 30px;
  }
`;

const Title = styled.h1`
  color: ${(props) => props.$theme.colors.dark};
  font-family: ${(props) => props.$theme.secondaryFont};
  font-size: 51px;
  font-weight: 700;
  line-height: 100%;
  @media (max-width: 960px) {
    display: none;
  }
`;

const Links = styled.div`
  display: inline-flex;
  gap: 12px;

  color: ${(props) => props.$theme.colors.grey1};
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  > a {
    text-decoration-line: underline;
  }

  .activeLink {
    text-decoration-line: underline;
  }
`;

const BottomBar = styled.div`
  max-width: 1440px;
  margin-bottom: 40px;
  border-bottom: 1.5px solid #e2e5e9;
  display: flex;
  justify-content: flex-end;
  padding-right: 138px;

  @media (max-width: 960px) {
    display: none;
  }
`;

const BottomLinks = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 15px;

  > a {
    padding-bottom: 20px;
    cursor: pointer;
    &.active {
      position: relative;
      text-decoration: none;
      &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0px;
        width: 100%;
        height: 3px;
        border-radius: 33px 32px 0px 0px;
        background-color: #6dd4f0;
      }
    }
  }
`;

const ContentContainer = styled.div`
  max-width: 1440px;
  min-height: fit-content;
  padding: 30px 120px;
  display: flex;
  flex-direction: column;
  gap: 60px;

  @media (max-width: 768px){
    padding: 30px 15px;
  }
`;

const Search = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 51px;
  background: #eef1f7;
  box-shadow: 0px 1px 2px #ffffff, inset 0px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 53px;

  > input {
    padding: 12px 21px;
    height: 51px;
    width: 95%;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 100%;
    letter-spacing: 1px;
    color: #9da7b1;
    background: transparent;
  }

  > svg {
    margin: 12px 21px;
  }
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
`;

const TableOfContents = styled.div`
  width: 35%;
  display: flex;
  flex-direction: column;
  gap: 30px;

  @media (max-width: 768px) {
    display: none;
  }

  > div {
    > h3 {
      color: ${(props) => props.$theme.colors.grey1};
      font-weight: 400;
      font-size: 18px;
      line-height: 100%;
      letter-spacing: 1px;
      margin-bottom: 6px;
    }
    > h2 {
      color: ${(props) => props.$theme.colors.dark};
      font-family: ${(props) => props.$theme.secondaryFont};
      font-weight: 500;
      font-size: 24px;
      line-height: 110%;
      letter-spacing: 0.5px;
      margin-bottom: 12px;
    }

    > h4 {
      color: ${(props) => props.$theme.colors.grey1};
      font-family: ${(props) => props.$theme.secondaryFont};
      font-weight: 600;
      font-size: 18px;
      line-height: 140%;
      margin-bottom: 12px;
      > span {
        margin-right: 20px;
      }
    }

    > ul {
      margin-left: 45px;
      li {
        color: ${(props) => props.$theme.colors.grey1};
        font-weight: 300;
        font-size: 12px;
        line-height: 150%;
      }
    }
  }
`;

const ContentText = styled.div`
  width: 65%;
  padding-left: 60px;

  @media (max-width: 768px){
    width: 100%;
    padding-left: 0;
  }

  > h2 {
    color: ${(props) => props.$theme.colors.dark};
    font-family: ${(props) => props.$theme.secondaryFont};
    font-weight: 700;
    font-size: 36px;
    line-height: 100%;
    margin-bottom: 15px;
  }

  > h4 {
    color: ${(props) => props.$theme.colors.grey1};
    font-family: ${(props) => props.$theme.secondaryFont};
    font-weight: 500;
    font-size: 24px;
    line-height: 110%;
    letter-spacing: 0.5px;
    margin-bottom: 24px;
  }

  > p {
    color: ${(props) => props.$theme.colors.grey1};
    font-weight: 300;
    font-size: 14px;
    line-height: 150%;
    margin-bottom: 24px;
  }
`;

export default Terms;
