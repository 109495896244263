import React, { useState } from "react";
import styled from "styled-components";
import { useTheme } from "@mui/material";

import ButtonDark from "../Buttons/ButtonDark";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import HomeBox from "../Cards/HomeBox";

function Section2() {
  const theme = useTheme();

  const [openBoxIndex, setOpenBoxIndex] = useState(null);

  const boxData = [
    {
      icon: "Brain",
      title: "You",
      subtitle: "Unique Profile",
      text: "Discover how your mind learns best.",
      longtext:
        "Our advanced AI creates a detailed map of your cognitive abilities, helping you understand how you learn best. This personalized blueprint becomes the foundation for tailoring your entire learning experience, ensuring that every interaction with Intellarity is optimized for your specific needs.",
    },
    {
      icon: "Chart",
      title: "Track",
      subtitle: "Analysis",
      text: "Monitor progress with adaptive assessments.",
      longtext:
        "Intellarity's dynamic assessment system adapts in real-time to your responses, providing a more accurate and comprehensive evaluation of your abilities. This continuous tracking allows for precise measurement of your growth and helps identify areas for improvement, ensuring that your learning path is always optimally challenging and rewarding.",
    },
    {
      icon: "Rocket",
      title: "Grow",
      subtitle: "Learning Paths",
      text: "Accelerate learning through your strengths.",
      longtext:
        "Leveraging your cognitive blueprint, Intellarity creates personalized learning paths that build upon your existing strengths. This approach not only accelerates your learning but also boosts confidence and motivation. By focusing on your natural talents, you'll achieve faster progress and deeper understanding in your chosen areas of study.",
    },
    {
      icon: "Lightbulb",
      title: "AI",
      subtitle: "AI-Powered",
      text: "Refinement with intelligent recommendations.",
      longtext:
        "Our sophisticated AI analyzes your performance data to provide insights and tailored assessments.",
    },
  ];
  const handleBoxClick = (index) => {
    if (openBoxIndex === index) {
      // Clicking an open box again closes it
      setOpenBoxIndex(null);
    } else {
      setOpenBoxIndex(index);
    }
  };

  return (
    <Container>
      <MobileOnly>
        <ButtonDark link="/assessment">Start Assessment</ButtonDark>
      </MobileOnly>
      <Boxes $theme={theme}>
        {boxData.map((box, index) => (
          <HomeBox
            key={index}
            isOpen={openBoxIndex === index}
            setIsOpen={() => handleBoxClick(index)}
            {...box}
          />
        ))}
      </Boxes>
      <Content $theme={theme}>
        <Title $theme={theme}>
          <span>Intellarity - </span>Revolutionizing Learning Through Cognitive
          Science
        </Title>
        <Body $theme={theme}>
          Welcome to Intellarity, where cutting-edge AI meets cognitive science
          to transform your learning experience. Our platform goes beyond
          traditional assessments, creating a personalized cognitive blueprint
          that unlocks your unique learning potential. Through adaptive testing
          and real-time analysis, we identify your cognitive strengths and
          learning style, tailoring educational content to match your individual
          needs. Whether you're a student aiming for academic excellence, a
          professional seeking to upskill, or simply curious about optimizing
          your cognitive abilities, Intellarity provides the insights and tools
          to enhance your learning journey. Discover a smarter way to learn,
          grow, and achieve with Intellarity - your personal guide to cognitive
          mastery.
        </Body>
        {/* <ButtonGroup>
          <ButtonDark link="/assessment">Start Assessment</ButtonDark>
          <LearnMoreButton $theme={theme}>
            <span>Learn More</span>
            <ArrowForwardIcon />
          </LearnMoreButton>
        </ButtonGroup> */}
      </Content>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  max-width: 1100px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  padding: 180px 15px 170px 15px;
  gap: 60px;
  width: 100%;
  @media (max-width: 960px) {
    padding: 0px 15px;
    flex-direction: column-reverse;
  }
`;

const Content = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  row-gap: 13px;
  margin-top: 80px;
  @media (max-width: 960px) {
    width: 100%;
    align-items: center;
    text-align: center;
  }
`;

const Title = styled.h1`
  color: ${(props) => props.$theme.colors.dark};
  font-family: ${(props) => props.$theme.secondaryFont};
  font-size: 46px;
  font-weight: 700;
  line-height: 100%;

  > span {
    color: ${(props) => props.$theme.colors.accent};
    font-family: ${(props) => props.$theme.secondaryFont};
  }

  @media (max-width: 600px) {
    width: fit-content;
    font-size: 45px;
  }
`;
const Body = styled.p`
  max-width: 480px;
  color: ${(props) => props.$theme.colors.grey2};
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;

  @media (max-width: 600px) {
    width: fit-content;
    font-size: 14px;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 30px;
  width: fit-content;
  margin-top: 15px;
  @media (max-width: 960px) {
    display: none;
  }

  > button:nth-child(1) {
    color: #f6f7f9;
    font-size: 15px;
    font-weight: 100;
    line-height: 150%;
    padding: 15px 24px;
  }
`;

const LearnMoreButton = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 150%;
  font-weight: 100;
  color: ${(props) => props.$theme.colors.grey2};
  cursor: pointer;
  transition: 0.25s ease-in-out all;
  > svg {
    transform: translateX(0px);
    opacity: 0;
    transition: 0.25s ease-in-out all;
    color: ${(props) => props.$theme.colors.dark};
    width: 16px;
  }

  &:hover {
    color: ${(props) => props.$theme.colors.dark};
    > svg {
      opacity: 1;
      transform: translateX(10px);
    }
  }
`;

const Boxes = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(215px, 1fr));
  grid-template-rows: auto auto;
  row-gap: 27px;
  column-gap: 20px;
  width: 50%;
  justify-items: right;
  padding-left: 20px;

  > div:nth-child(2) {
    margin-top: 120px;
  }
  > div:nth-child(3) {
    margin-top: -120px;
  }

  @media (max-width: 960px) {
    place-self: center;
    justify-items: center;
    width: auto;
    padding-left: 0px;
    column-gap: 10px;
    row-gap: 15px;
    > div:nth-child(2) {
      margin-top: 0px;
    }
    > div:nth-child(3) {
      margin-top: 0px;
    }
  }
  @media (max-width: 600px) {
    grid-template-columns: repeat(2, minmax(180px, 1fr));
  }
`;

const MobileOnly = styled.div`
  display: none;
  width: 100%;
  justify-content: center;
  @media (max-width: 960px) {
    display: flex;
    > button {
      font-weight: 100;
      line-height: 150%;
      transform: scale(1.1);
    }
  }
`;

export default Section2;
