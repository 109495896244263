import React from "react";
import { useNavigate } from "react-router-dom";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styled from "styled-components";

function FAQ() {
  const navigate = useNavigate();
  return (
    <Container id="faq">
      <TitleContainer>
        <h2>Common Questions</h2>
        <h6>
          Explore our frequently asked questions to learn more about Intellarity
          and how it can revolutionize your learning experience.
        </h6>
        <h4>Didn't find the answer to your question?</h4>
        <div className="button_group">
          <button onClick={() => navigate("/contact")}>Contact Us</button>
        </div>
      </TitleContainer>
      <FaqContainer>
        <h6>F. A. Q</h6>
        <StyledAccordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            What is Intellarity and how does it work?
          </AccordionSummary>
          <AccordionDetails>
            Intellarity is an innovative AI-driven learning platform that
            creates personalized learning experiences based on your unique
            cognitive blueprint. It uses advanced assessments to understand your
            learning style and then tailors content and learning paths to
            optimize your learning outcomes.
          </AccordionDetails>
        </StyledAccordion>
        <StyledAccordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            How does Intellarity create my cognitive blueprint?
          </AccordionSummary>
          <AccordionDetails>
            Intellarity uses a comprehensive initial assessment that evaluates
            various aspects of your cognitive profile, including problem-solving
            skills, critical thinking, memory retention, and learning
            preferences. This data is then analyzed by our AI to create your
            personalized cognitive blueprint.
          </AccordionDetails>
        </StyledAccordion>
        <StyledAccordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4-content"
            id="panel4-header"
          >
            Can Intellarity adapt to different learning contexts?
          </AccordionSummary>
          <AccordionDetails>
            Absolutely! Intellarity is designed to be versatile and can adapt to
            various learning contexts, including academic studies, professional
            development, and skill acquisition. The platform's AI continuously
            refines your learning path based on your progress and feedback.
          </AccordionDetails>
        </StyledAccordion>
        <StyledAccordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel5-content"
            id="panel5-header"
          >
            How often is the content on Intellarity updated?
          </AccordionSummary>
          <AccordionDetails>
            We regularly update our content to ensure it remains relevant and
            up-to-date. Our team will collaborate with industry experts and
            academicians to keep the learning material aligned with current
            standards and advancements in various fields.
          </AccordionDetails>
        </StyledAccordion>
        <StyledAccordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel6-content"
            id="panel6-header"
          >
            Can Intellarity accommodate users with learning disabilities?
          </AccordionSummary>
          <AccordionDetails>
            Absolutely! Intellarity was born from our founder's personal
            experience with learning challenges. Recognizing that everyone
            learns differently and that his struggles were likely shared by
            many, he developed Intellarity to be inherently inclusive. Our
            AI-driven cognitive blueprint assessment is designed to identify
            unique learning needs, including learning disabilities, and tailor
            learning paths accordingly. We collaborate with experts in special
            education to ensure our platform remains sensitive and adaptive to
            diverse learning needs. At Intellarity, we believe that personalized
            learning isn't just a feature – it's the core of our mission to make
            education accessible and effective for all learners.
          </AccordionDetails>
        </StyledAccordion>
        <StyledAccordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel7-content"
            id="panel7-header"
          >
            What types of content does Intellarity offer?
          </AccordionSummary>
          <AccordionDetails>
            Intellarity offers a wide range of content types. As the project
            progresses, it will include text-based materials, videos,
            interactive simulations, and quizzes. The specific content you
            receive is tailored to your cognitive blueprint and learning
            objectives.
          </AccordionDetails>
        </StyledAccordion>
        <StyledAccordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel8-content"
            id="panel8-header"
          >
            How does Intellarity measure my progress?
          </AccordionSummary>
          <AccordionDetails>
            Intellarity uses a combination of assessments, quizzes, and
            performance tracking to measure your progress. Our AI analyzes this
            data to provide you with detailed insights into your learning
            journey and to continuously optimize your learning path.
          </AccordionDetails>
        </StyledAccordion>
        <StyledAccordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel9-content"
            id="panel9-header"
          >
            Can I use Intellarity on different devices?
          </AccordionSummary>
          <AccordionDetails>
            Yes, Intellarity is a web application, allowing you to access it
            across various devices, including desktop computers, tablets, and
            smartphones. Our platform adapts to different screen sizes to ensure
            a seamless learning experience regardless of the device you're
            using.
          </AccordionDetails>
        </StyledAccordion>
        <StyledAccordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel10-content"
            id="panel10-header"
          >
            What if I'm not satisfied with my learning experience on
            Intellarity?
          </AccordionSummary>
          <AccordionDetails>
            We're committed to ensuring your satisfaction. If you're not happy
            with your experience, please contact our support team. We'll work
            with you to understand your concerns and adjust your learning path
            as needed. We also offer a money-back guarantee for our premium
            services.
          </AccordionDetails>
        </StyledAccordion>
        <StyledAccordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel11-content"
            id="panel11-header"
          >
            How does Intellarity compare to traditional learning methods?
          </AccordionSummary>
          <AccordionDetails>
            While traditional methods offer structure, Intellarity adds a
            crucial layer of personalization. By tailoring the educational
            journey to each individual's unique cognitive patterns, Intellarity
            offers a more efficient, engaging, and deeply resonant learning
            experience compared to one-size-fits-all approaches.
          </AccordionDetails>
        </StyledAccordion>
        <StyledAccordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel12-content"
            id="panel12-header"
          >
            Can I collaborate with other learners on Intellarity?
          </AccordionSummary>
          <AccordionDetails>
            Not now but we plan to collaborative learning features. Where you
            can participate in discussion forums, group projects, and
            peer-to-peer learning activities. These features are designed to
            complement your personalized learning path and enhance your overall
            learning experience.
          </AccordionDetails>
        </StyledAccordion>
        <StyledAccordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel13-content"
            id="panel13-header"
          >
            How often should I take the cognitive assessment?
          </AccordionSummary>
          <AccordionDetails>
            It takes roughly twenty assessment to create your cognitive
            blueprint, Intellarity continuously adapts based on your
            interactions with the platform. However, we recommend continous
            assessment to capture any significant changes in your learning style
            or cognitive abilities.
          </AccordionDetails>
        </StyledAccordion>
        <StyledAccordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel14-content"
            id="panel14-header"
          >
            Can Intellarity integrate with other learning management systems?
          </AccordionSummary>
          <AccordionDetails>No, not at the moment.</AccordionDetails>
        </StyledAccordion>
        <StyledAccordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel15-content"
            id="panel15-header"
          >
            What kind of support does Intellarity offer?
          </AccordionSummary>
          <AccordionDetails>
            Intellarity offers comprehensive support including FAQs and email
            support. experience.
          </AccordionDetails>
        </StyledAccordion>
      </FaqContainer>
    </Container>
  );
}

const Container = styled.section`
  background: #202228;
  max-width: 1440px;
  min-height: fit-content;
  padding: 120px 120px 250px 120px;
  display: flex;
  gap: 55px;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  @media (max-width: 968px) {
    padding: 80px 15px 120px 15px;
  }
`;

const TitleContainer = styled.div`
  width: 32%;

  h2 {
    font-family: sans-serif;
    color: #fff;
    font-weight: 700;
    font-size: 45px;
    line-height: 100%;
    margin-bottom: 15px;
  }

  h4 {
    margin-top: 40px;
    font-weight: 700;
    font-size: 14px;
    line-height: 150%;
    color: #f6f7f9;
  }

  h6 {
    color: #9da7b1;
    font-weight: 300;
    font-size: 14px;
    line-height: 150%;
  }

  .button_group {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    margin-top: 15px;

    button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 15px 24px;
      gap: 30px;
      background: #ffffff;
      box-shadow: 0px 6px 14px rgba(74, 91, 111, 0.23);
      border-radius: 1000px;
    }
  }

  @media (max-width: 960px) {
    width: 30%;
  }

  @media (max-width: 768px) {
    width: 100%;
    h2 {
      font-size: 36px;
    }
  }
`;

const FaqContainer = styled.div`
  width: 68%;

  h6 {
    font-weight: 400;
    font-size: 18px;
    line-height: 100%;
    letter-spacing: 1px;
    color: #9da7b1;
    margin-bottom: 20px;
  }

  @media (max-width: 960px) {
    width: 70%;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const StyledAccordion = styled(Accordion)`
  background-color: transparent !important;
  color: #f6f7f9 !important;
  box-shadow: none !important;
  padding: 5px 0;

  .MuiButtonBase-root {
    padding-left: 0 !important;
  }

  .MuiAccordionSummary-expandIconWrapper {
    background: #fff;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      font-size: 18px;
    }
  }
  .MuiAccordionSummary-content {
    max-width: 90%;
    margin-right: auto !important ;
  }
  .MuiAccordionSummary-content.Mui-expanded,
  &.MuiAccordion-root.Mui-expanded {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .MuiAccordionDetails-root {
    padding: 0 !important;
    color: #9da7b1;
    font-size: 14px;
    font-weight: lighter;
  }
`;

export default FAQ;
