import React, { useState, useEffect } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useTheme, FormControlLabel, Checkbox } from "@mui/material";
import styled from "styled-components";
import api from "../../Components/API/apiService";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { ReactComponent as QuestionIcon } from "../../Icons/Profile Icons/Question.svg";
import { ReactComponent as DotIcon } from "../../Icons/Profile Icons/Dot.svg";
import DemoImg from "../../Images/Assessment/Demo.jpg";
import { FaKeyboard } from "react-icons/fa";
import { MdSpaceBar } from "react-icons/md";

const Instructions = () => {
  const [currentInstruction, setCurrentInstruction] = useState(null);
  const [doNotShowAgain, setDoNotShowAgain] = useState(false);
  const { userAssessmentID } = useParams(); // Destructure to get the parameter directly

  const theme = useTheme();
  const navigate = useNavigate();

  const toggleInstruction = (instruction) => {
    setCurrentInstruction((prevInstruction) =>
      prevInstruction === instruction ? null : instruction
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);

  const handleDoNotShowAgain = (event) => {
    setDoNotShowAgain(event.target.checked);
    api.post("user/showIntro");
  };

  return (
    <Container>
      <Header>
        <NavigationButton
          $theme={theme}
          onClick={() => navigate("/profile/overview")}
        >
          <NavIcon>
            <ChevronLeftIcon />
          </NavIcon>
          Home
        </NavigationButton>
        <Title $theme={theme}>Instruction</Title>
        <NavigationButton $theme={theme}>
          Start
          <NavIcon
            onClick={() =>
              navigate(`/assessment/instructions/${userAssessmentID}`)
            }
          >
            <ChevronRightIcon />
          </NavIcon>
        </NavigationButton>
      </Header>
      <MobileTitle $theme={theme}>Instruction</MobileTitle>
      <InnerContainer>
        <Column>
          <InstructionBox
            onClick={() => toggleInstruction(1)}
            className={currentInstruction === 1 ? "active" : ""}
          >
            <Number $theme={theme}>01</Number>
            <Content $theme={theme}>
              <h4>Progress and Time Limits</h4>
              <p>This bar shows how far along you are in the test.</p>
              <p>
                Tests may have time limits for answering questions, viewing
                content, or both.
              </p>
            </Content>
          </InstructionBox>
          <InstructionBox
            onClick={() => toggleInstruction(2)}
            className={currentInstruction === 2 ? "active" : ""}
          >
            <Number $theme={theme}>02</Number>
            <Content $theme={theme}>
              <h4>Content and Questions</h4>
              <p>
                Each question relates to the content, which can be text, audio,
                image, or video.
              </p>
            </Content>
          </InstructionBox>
        </Column>
        <MiddleContainer>
          <DemoContainer $currentInstruction={currentInstruction}>
            <img src={DemoImg} alt="Instruction" />
          </DemoContainer>
          <ShowOption>
            <FormControlLabel
              control={
                <Checkbox
                  checked={doNotShowAgain}
                  onChange={handleDoNotShowAgain}
                />
              }
              label="Do Not Show Again"
              sx={{
                "& .MuiFormControlLabel-label": {
                  color: "#515357",
                  fontSize: "15px",
                  fontWeight: 400,
                  marginLeft: "-2px",
                },
                "& span span svg path": {
                  fill: "#515357",
                  width: "14px",
                  height: "14px",
                },
                "& span svg path": {
                  fill: "#515357",
                },
              }}
            />
          </ShowOption>
        </MiddleContainer>
        <Column>
          <InstructionBox
            onClick={() => toggleInstruction(3)}
            className={currentInstruction === 3 ? "active" : ""}
          >
            <Number $theme={theme}>03</Number>
            <Content $theme={theme}>
              <h4>Answers</h4>
              <p>
                Answers can be inputs, buttons, images, audio, video, or text.
                You may need to select one or more answers, or simply input an
                answer.
              </p>
            </Content>
          </InstructionBox>
          <InstructionBox
            onClick={() => toggleInstruction(4)}
            className={currentInstruction === 4 ? "active" : ""}
          >
            <Number $theme={theme}>04</Number>
            <Content $theme={theme}>
              <h4>Keyboard Shortcuts</h4>
              <p>
                You can select answers by pressing the keys{" "}
                <FaKeyboard className="icon" /> <b>1</b> to <b>9</b>, and when
                necessary, press the <MdSpaceBar className="icon" />{" "}
                <b>SPACEBAR</b> to submit.
              </p>
            </Content>
          </InstructionBox>
        </Column>
      </InnerContainer>
      <Footer $theme={theme}>
        <p>
          <NavLink to="/terms">Terms And Conditions</NavLink>
          <DotIcon />
          <NavLink to="/terms"> Policies</NavLink>
        </p>
        <SupportButton $theme={theme}>
          <div>
            <QuestionIcon />
          </div>
          <NavLink to="/support"> Support</NavLink>
        </SupportButton>
      </Footer>
    </Container>
  );
};

const Container = styled.div`
  padding: 0px 60px;
  @media (max-width: 960px) {
    padding: 0px;
  }
`;

const Header = styled.div`
  display: flex;
  padding: 50px 0px;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 960px) {
    padding: 50px 15px 35px 15px;
  }
`;

const Title = styled.h1`
  color: ${(props) => props.$theme.colors.dark};
  font-size: 24px;
  font-weight: 500;
  line-height: 110%;
  letter-spacing: 0.5px;
  font-family: ${(props) => props.$theme.secondaryFont};

  @media (max-width: 960px) {
    display: none;
  }
`;

const MobileTitle = styled.h1`
  color: ${(props) => props.$theme.colors.dark};
  font-size: 36px;
  font-weight: 700;
  line-height: 100%;
  font-family: ${(props) => props.$theme.secondaryFont};
  text-align: center;
  margin-bottom: 20px;
  display: none;

  @media (max-width: 960px) {
    display: block;
  }
`;

const NavigationButton = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 12px;
  color: ${(props) => props.$theme.colors.dark};
  font-size: 15px;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: 1px;
`;

const NavIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 47px;
  height: 47px;
  box-shadow: 0px 5px 10px 2px #d8d8d8;
  cursor: pointer;
  background-color: #fff;
  border-radius: 50%;
  transition: 0.2s ease-in-out;
  > svg {
    width: 25px;
  }

  &:hover {
    box-shadow: none;
  }
`;

const InnerContainer = styled.div`
  display: flex;
  width: 90%;
  padding: 60px 85px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  gap: 30px;
  border-radius: 51px;
  background: #fff;
  box-shadow: 0px 6px 14px 0px rgba(74, 91, 111, 0.23);

  @media (max-width: 1200px) {
    flex-direction: column;
    width: 100%;
    gap: 50px;
    padding: 60px 15px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;

  @media (max-width: 1200px) {
    flex-direction: row;
    gap: 15px;
    max-width: 95%;
  }
`;

const InstructionBox = styled.div`
  display: flex;
  width: 210px;
  align-items: flex-start;
  gap: 21px;
  cursor: pointer;
  transition: 0.1s ease-in-out;

  &:hover {
    opacity: 0.7;
  }

  &.active:hover {
    opacity: 1;
  }

  &.active {
    > h2 {
      background: var(
        --Accent_gradient,
        radial-gradient(
          130.62% 130.62% at 50% 0%,
          #6dd4f0 0%,
          #6dd4f0 13.02%,
          #1a839f 100%
        )
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    > div h4 {
      background: var(
        --Accent_gradient,
        radial-gradient(
          130.62% 130.62% at 50% 0%,
          #6dd4f0 0%,
          #6dd4f0 13.02%,
          #1a839f 100%
        )
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
`;

const Number = styled.h2`
  color: ${(props) => props.$theme.colors.dark};
  font-family: ${(props) => props.$theme.secondaryFont};
  font-size: 22px;
  font-weight: 600;
  line-height: 110%;
  letter-spacing: 0.5px;

  @media (max-width: 960px) {
    font-size: 21px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;

  > h4 {
    color: ${(props) => props.$theme.colors.dark};
    font-size: 15px;
    font-weight: 700;
    line-height: 150%;
    @media (max-width: 960px) {
      font-size: 14px;
    }
  }

  > p {
    color: ${(props) => props.$theme.colors.grey1};
    font-size: 13px;
    font-weight: 100;
    line-height: 150%;
    @media (max-width: 960px) {
      font-size: 12px;
    }
  }

  .icon {
    vertical-align: middle;
    margin: 1px; /* Adjust this value as needed */
  }
`;

const MiddleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const DemoContainer = styled.div`
  width: 510px;
  height: 250px;
  border-radius: 36px;
  border: 3px solid #e2e5e9;
  box-shadow: 0px 6px 14px 0px rgba(74, 91, 111, 0.23);
  overflow: hidden;

  @media (max-width: 960px) {
    width: 100%;
    height: auto;
    box-shadow: 2px 8px 20px 0px rgb(46 47 48 / 50%);
  }

  > img {
    width: 100%;
    object-fit: cover;
    transition: all 0.35s ease-in-out;

    transform: scale(
      ${(props) => {
        switch (props.$currentInstruction) {
          case 1:
            return "4";
          case 2:
            return "1.45";
          case 3:
            return "1.6";
          default:
            return "1";
        }
      }}
    );
    transform-origin: ${(props) => {
      switch (props.$currentInstruction) {
        case 1:
          return "30px top";
        case 2:
          return "center -30px";
        case 3:
          return "center 185px";
        default:
          return "";
      }
    }};
  }
`;

const ShowOption = styled.div`
  margin: 20px 0 -20px 0;
  align-self: center;
`;

const Footer = styled.div`
  width: 100%;
  padding: 60px 0 30px 0;
  float: right;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 960px) {
    flex-direction: column;
    row-gap: 40px;
  }

  > p {
    color: ${(props) => props.$theme.colors.dark};
    font-size: 15px;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 1px;
    display: flex;
    align-items: center;

    > a {
      transition: 0.3s ease-in-out;
      &:hover {
        opacity: 0.5;
      }
    }

    > svg {
      margin: 3px 15px 0 14px;
    }
  }
`;

const SupportButton = styled.div`
  display: flex;
  align-items: center;

  > div {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    flex-shrink: 0;
    border-radius: 47.442px;
    background: #fff;
    box-shadow: 0px 5.581px 13.953px 0px rgba(74, 91, 111, 0.23);
  }

  > a {
    transition: 0.3s ease-in-out;
    color: ${(props) => props.$theme.colors.dark};
    font-size: 15px;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 1px;
    display: flex;
    align-items: center;

    &:hover {
      opacity: 0.5;
    }
  }
`;
export default Instructions;
