import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useTheme } from "@mui/material";
import VideoModal from "../../Components/Modals/VideoModal";
import { ReactComponent as PlayIcon } from "../../Icons/Assessment/play.svg";
import api from "../../Components/API/apiService";

// Custom hook for handling question timers
const useQuestionTimer = (
  metaTimeLimit,
  metaVisibilityTime,
  onTimeLimit,
  onVisibilityTimeReached
) => {
  const [visibilityTimeStop, setVisibilityTimeStop] = useState(false);
  const [timeStop, setTimeStop] = useState(false);
  const visibilityTimerRef = useRef(null);
  const timeLimitTimerRef = useRef(null);

  const clearTimers = useCallback(() => {
    if (visibilityTimerRef.current) {
      clearTimeout(visibilityTimerRef.current);
    }
    if (timeLimitTimerRef.current) {
      clearTimeout(timeLimitTimerRef.current);
    }
  }, []);

  useEffect(() => {
    const timeLimitMs = metaTimeLimit ? parseInt(metaTimeLimit, 10) : null;
    const visibilityTimeMs = metaVisibilityTime
      ? parseInt(metaVisibilityTime, 10)
      : null;

    console.log("Setting up timers:", { timeLimitMs, visibilityTimeMs });

    if (visibilityTimeMs) {
      visibilityTimerRef.current = setTimeout(() => {
        console.log("Visibility time reached");
        setVisibilityTimeStop(true);
        onVisibilityTimeReached();
      }, visibilityTimeMs);
    }

    if (timeLimitMs) {
      timeLimitTimerRef.current = setTimeout(() => {
        console.log("Time limit reached");
        setTimeStop(true);
        onTimeLimit();
      }, timeLimitMs);
    }

    return clearTimers;
  }, [
    metaTimeLimit,
    metaVisibilityTime,
    onTimeLimit,
    onVisibilityTimeReached,
    clearTimers,
  ]);

  return { visibilityTimeStop, timeStop, clearTimers };
};

const Question = ({ questionData, handleNextQuestion, totalQuestions }) => {
  const navigate = useNavigate();
  const [videoPlayed, setVideoPlayed] = useState(false);
  const [textInput, setTextInput] = useState("");
  const [mcqSelected, setMcqSelected] = useState([]);
  const [startTime, setStartTime] = useState(Date.now());
  const [error, setError] = useState(null);

  const theme = useTheme();

  const { userAssessmentQuestionID, order, metaTimeLimit, metaVisibilityTime } =
    questionData || {};

  const {
    content,
    question,
    numberOfcorrectAnswers,
    metaContentTypeID,
    questionAnswers,
  } = questionData?.question || {};

  const handleTimeoutSubmit = useCallback(() => {
    const timeoutAnswer = [
      {
        questionAnswerID: -1,
        answerText: "-1",
        order: 1,
      },
    ];

    submitAnswer(timeoutAnswer);
  }, []);

  const { visibilityTimeStop, timeStop, clearTimers } = useQuestionTimer(
    metaTimeLimit,
    metaVisibilityTime,
    handleTimeoutSubmit,
    () => {}
  );

  useEffect(() => {
    setMcqSelected([]);
    setTextInput("");
    setStartTime(Date.now());
    setError(null);
    clearTimers();
  }, [order, clearTimers]);

  const submitAnswer = useCallback(
    async (answers) => {
      const payload = {
        userAssessmentQuestionID,
        responseTime: Date.now() - startTime,
        answers,
      };

      try {
        await api.post("assessment/answer", payload);

        if (order === totalQuestions) {
          navigate("/profile/overview");
        } else {
          handleNextQuestion();
        }
      } catch (error) {
        console.error("Error submitting answer:", error);
        setError(
          "An error occurred while submitting your answer. Please try again."
        );
      }
    },
    [
      userAssessmentQuestionID,
      startTime,
      order,
      totalQuestions,
      navigate,
      handleNextQuestion,
    ]
  );

  const toggleMcqSelection = useCallback((selectedAnswerID) => {
    setMcqSelected((prevSelected) =>
      prevSelected.includes(selectedAnswerID)
        ? prevSelected.filter((id) => id !== selectedAnswerID)
        : [...prevSelected, selectedAnswerID]
    );
  }, []);

  const handleMcqSelection = useCallback(
    (selectedAnswerID) => {
      if (numberOfcorrectAnswers === 1) {
        setMcqSelected([selectedAnswerID]);
        submitAnswer([
          { questionAnswerID: selectedAnswerID, answerText: "", order: 1 },
        ]);
      } else {
        toggleMcqSelection(selectedAnswerID);
      }
    },
    [numberOfcorrectAnswers, submitAnswer, toggleMcqSelection]
  );

  const handleKeyPress = useCallback(
    (event) => {
      const key = event.key;
      if (key >= 1 && key <= 9 && questionAnswers) {
        const index = parseInt(key) - 1;
        const selectedAnswer = questionAnswers[index];
        if (selectedAnswer) {
          handleMcqSelection(selectedAnswer.questionAnswerID);
        }
      } else if (key === " " && numberOfcorrectAnswers !== 1) {
        handleSubmit();
      }
    },
    [questionAnswers, numberOfcorrectAnswers, handleMcqSelection]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  const handleSubmit = useCallback(() => {
    const answers = mcqSelected.map((id, index) => ({
      questionAnswerID: id,
      answerText: "",
      order: index + 1,
    }));

    if (textInput) {
      answers.push({
        questionAnswerID: null,
        answerText: textInput,
        order: 1,
      });
    }

    submitAnswer(answers);
  }, [mcqSelected, textInput, submitAnswer]);

  const assetsBasePath = process.env.PUBLIC_URL + "/assets";

  const renderContent = useMemo(() => {
    if (visibilityTimeStop) return null;

    switch (metaContentTypeID) {
      case 2:
        return (
          <ContentAudio controls>
            <source src={`${assetsBasePath}/audio/${content}`} />
          </ContentAudio>
        );
      case 3:
        return <ContentImg src={`${assetsBasePath}/image/${content}`} />;
      case 5:
        return (
          <div style={{ position: "relative", opacity: videoPlayed ? 0 : 1 }}>
            <ContentVideo onClick={() => setVideoPlayed(true)}>
              <source src={`${assetsBasePath}/video/${content}`} />
            </ContentVideo>
            <PLayButton onClick={() => setVideoPlayed(true)}>
              <PlayIcon />
            </PLayButton>
          </div>
        );
      default:
        return null;
    }
  }, [
    metaContentTypeID,
    visibilityTimeStop,
    content,
    videoPlayed,
    assetsBasePath,
  ]);

  if (!questionData) return <p>Loading...</p>;

  return (
    <Container>
      <Header
        $theme={theme}
        className={
          metaContentTypeID === 2
            ? "content_audio"
            : metaContentTypeID === 3
            ? "content_img"
            : metaContentTypeID === 5
            ? "content_video"
            : ""
        }
      >
        {!visibilityTimeStop && (
          <Title $theme={theme} $type={metaContentTypeID}>
            <h6>Question. {order}</h6>
            {metaContentTypeID === 1 && <h4>{content}</h4>}
            <h1>{question}</h1>
          </Title>
        )}

        {renderContent}
      </Header>

      <VideoModal open={videoPlayed} onClose={() => setVideoPlayed(false)}>
        <video controls>
          <source src={`${assetsBasePath}/video/${content}`} />
        </video>
      </VideoModal>

      <MCQs>
        {questionAnswers &&
          questionAnswers.map((answer, index) => (
            <React.Fragment key={answer.questionAnswerID}>
              {answer.metaContentTypeID === 1 ? (
                <Option
                  $theme={theme}
                  className={
                    mcqSelected.includes(answer.questionAnswerID)
                      ? "selected"
                      : ""
                  }
                  onClick={() => handleMcqSelection(answer.questionAnswerID)}
                >
                  {index + 1}. {answer.answer}
                </Option>
              ) : answer.metaContentTypeID === 2 ? (
                <AnswerAudio>
                  <RadioButton>
                    <input
                      type="radio"
                      checked={mcqSelected.includes(answer.questionAnswerID)}
                      onChange={() =>
                        handleMcqSelection(answer.questionAnswerID)
                      }
                      className={
                        mcqSelected.includes(answer.questionAnswerID)
                          ? "selected"
                          : ""
                      }
                    />
                    <span className="checkmark"></span>
                  </RadioButton>
                  <audio controls>
                    <source
                      src={`${assetsBasePath}/audio/${answer.answer}`}
                      type="audio/mp3"
                    />
                  </audio>
                </AnswerAudio>
              ) : answer.metaContentTypeID === 3 ? (
                <AnswerImg>
                  <RadioButton>
                    <input
                      type="radio"
                      checked={mcqSelected.includes(answer.questionAnswerID)}
                      onChange={() =>
                        handleMcqSelection(answer.questionAnswerID)
                      }
                      className={
                        mcqSelected.includes(answer.questionAnswerID)
                          ? "selected"
                          : ""
                      }
                    />
                    <span className="checkmark"></span>
                  </RadioButton>
                  <div>
                    <img
                      src={`${assetsBasePath}/image/${answer.answer}`}
                      alt="Img"
                      onClick={() =>
                        handleMcqSelection(answer.questionAnswerID)
                      }
                    />
                  </div>
                </AnswerImg>
              ) : answer.metaContentTypeID === 5 ? (
                <AnswerVideo>
                  <RadioButton>
                    <input
                      type="radio"
                      checked={mcqSelected.includes(answer.questionAnswerID)}
                      onChange={() =>
                        handleMcqSelection(answer.questionAnswerID)
                      }
                      className={
                        mcqSelected.includes(answer.questionAnswerID)
                          ? "selected"
                          : ""
                      }
                    />
                    <span className="checkmark"></span>
                  </RadioButton>
                  <video
                    controls
                    onClick={() => handleMcqSelection(answer.questionAnswerID)}
                  >
                    <source
                      src={`${assetsBasePath}/video/${answer.answer}`}
                      type="video/mp4"
                    />
                  </video>
                </AnswerVideo>
              ) : answer.metaContentTypeID === 6 ? (
                <TextInputWrapper>
                  <TextInputLabel>Enter your answer:</TextInputLabel>
                  <TextInputField
                    value={textInput}
                    onChange={(e) => setTextInput(e.target.value)}
                    placeholder="Type your answer here..."
                  />
                </TextInputWrapper>
              ) : null}
            </React.Fragment>
          ))}
      </MCQs>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <Footer $theme={theme}>
        {(numberOfcorrectAnswers !== 1 || metaContentTypeID === 6) && (
          <Submit $theme={theme} onClick={handleSubmit}>
            Submit
          </Submit>
        )}
      </Footer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 20px;
  box-sizing: border-box;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 980px;
  margin: 0 auto;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;

  > h6 {
    color: ${(props) => props.$theme.colors.grey2};
    font-size: clamp(15px, 2vw, 18px);
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: 1px;
    order: -1;
  }

  > h1 {
    color: ${(props) => props.$theme.colors.dark};
    font-family: ${(props) => props.$theme.secondaryFont};
    font-size: clamp(30px, 5vw, 45px);
    font-weight: 600;
    line-height: 1.2;
  }

  > h4 {
    color: ${(props) => props.$theme.colors.grey1};
    font-family: ${(props) => props.$theme.secondaryFont};
    font-size: clamp(25px, 4vw, 30px);
    font-weight: 400;
  }

  @media (min-width: 768px) {
    margin-right: 20px;
    flex: 1;
  }
`;

const ContentAudio = styled.audio`
  width: 100%;
  max-width: 300px;
  margin: 20px 0;

  @media (min-width: 768px) {
    margin: 0;
  }
`;

const ContentImg = styled.img`
  width: 100%;
  max-width: 350px;
  height: auto;
  object-fit: cover;
  border-radius: 36px;
  margin: 20px 0;

  @media (min-width: 768px) {
    margin: 0;
  }
`;

const ContentVideo = styled.video`
  width: 100%;
  max-width: 365px;
  height: auto;
  border-radius: 40px;
  object-fit: cover;
  box-shadow: 0px 14px 36px 0px #dee4e9;
  border: 5px solid #fff;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  margin: 20px 0;

  @media (min-width: 768px) {
    margin: 0;
  }

  &:hover {
    box-shadow: 0px 14px 36px 0px #c9d2d9;
  }
`;

const PLayButton = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 57px;
  height: 57px;
  background: #202228;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background 0.3s ease;

  &:hover {
    background: #9da7b18e;
  }
`;

const MCQs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 30px auto;
  width: 100%;
  max-width: 980px;
`;

const Option = styled.div`
  display: flex;
  align-items: center;
  padding: 15px;
  border-radius: 1000px;
  background: #fff;
  box-shadow: 0px 6px 14px 0px rgba(74, 91, 111, 0.23);
  color: ${(props) => props.$theme.colors.grey1};
  font-size: 15px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 1px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    box-shadow: 0px 8px 18px 0px rgba(74, 91, 111, 0.3);
  }

  &.selected {
    background: linear-gradient(
        118deg,
        #ffe2b8 -4.95%,
        rgba(246, 247, 249, 0) 114.52%
      ),
      linear-gradient(116deg, #6dd4f0 31.06%, #31ccf7 121.65%);
  }
`;

const AnswerAudio = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

const AnswerImg = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;

  > div {
    width: 175px;
    height: 145px;
    overflow: hidden;
    border-radius: 36px;
    box-shadow: 0px 14px 36px 0px #dee4e9;
  }

  > div img > div img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;

    &:hover {
      transform: scale(1.15);
    }
  }
`;

const AnswerVideo = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;

  > video {
    width: 175px;
    height: 145px;
    border-radius: 36px;
    box-shadow: 0px 14px 36px 0px #dee4e9;
    cursor: pointer;
  }
`;

const RadioButton = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .checkmark {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #edeff3;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.31) inset, 0px 3px 1px 0px #fff;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
    top: 5px;
    left: 5px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: radial-gradient(
        134.73% 52% at -47% 33%,
        #ffe2b8 0%,
        rgba(246, 247, 249, 0) 100%
      ),
      radial-gradient(
        130.62% 130.62% at 50% 0%,
        #6dd4f0 0%,
        #6dd4f0 13.02%,
        #1a839f 100%
      );
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.31) inset, 0px 3px 1px 0px #fff;
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: auto;
  padding: 20px;
`;

const Button = styled.button`
  padding: 21px 42px;
  border-radius: 1000px;
  font-size: 15px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 1px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  border: none;
  outline: none;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0px 8px 18px 0px rgba(74, 91, 111, 0.3);
  }

  @media (max-width: 960px) {
    padding: 15px 30px;
    font-size: 14px;
  }
`;

const Submit = styled(Button)`
  background: ${(props) => props.$theme.colors.dark};
  color: #f1f1f1;
`;

const TextInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const TextInputLabel = styled.label`
  font-size: 16px;
  margin-bottom: 8px;
  color: ${(props) => props.$theme.colors.grey1};
`;

const TextInputField = styled.textarea`
  width: 100%;
  height: 100px;
  padding: 12px;
  border: 1px solid ${(props) => props.$theme.colors.grey2};
  border-radius: 8px;
  font-size: 14px;
  resize: vertical;
  transition: border-color 0.3s ease;

  &:focus {
    outline: none;
    border-color: ${(props) => props.$theme.colors.primary};
  }
`;

const ErrorMessage = styled.div`
  color: red;
  margin-top: 10px;
  text-align: center;
`;

export default Question;
