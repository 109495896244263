import React from "react";
import styled from "styled-components";
import { useTheme } from "@mui/material";
import ButtonDark from "../Buttons/ButtonDark";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";

function AssessmentCard({ title, text, tags, image, link, learn_more }) {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <Container>
      <img src={image} />
      <Tags>
        {tags.map((tag, i) => (
          <Tag $theme={theme} key={i}>{tag}</Tag>
        ))}
      </Tags>
      <Title $theme={theme}>{title}</Title>
      <Text $theme={theme}>{text}</Text>
      <ButtonGroup>
        <ButtonDark link="/assessment">Start Assessment</ButtonDark>
        <LearnMoreButton $theme={theme} onClick={() => navigate(learn_more)}>
          <span>Learn More</span>
          <ArrowForwardIcon />
        </LearnMoreButton>
      </ButtonGroup>
    </Container>
  );
}

const Container = styled.div`
  width: 320px;
  max-width: 320px;
  min-height: 405px;
  display: flex;
  padding: 6px 6px 15px 6px;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  border-radius: 36px;
  background: #fff;
  box-shadow: 0px 6px 14px 0px rgba(74, 91, 111, 0.23);
  position: relative;

  > img {
    width: 100%;
    border-radius: 36px;
  }

  @media (max-width: 1100px) {
    width: 305px;
  }

  @media (max-width: 960px) {
    width: 285px;
  }
`;

const Tags = styled.div`
  display: flex;
  width: 80%;
  align-items: flex-start;
  align-content: flex-start;
  gap: 10px;
  flex-wrap: wrap;
  position: absolute;
  top: 15px;
  left: 15px;
`;

const Tag = styled.div`
  display: flex;
  padding: 9px 21px;
  justify-content: center;
  align-items: center;
  gap: 30px;
  border-radius: 1000px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 6px 14px 0px rgba(74, 91, 111, 0.23);
  font-size: 11px;
  font-weight: 400;
  line-height: 110%;
  color: ${(props) => props.$theme.colors.dark};
`;

const Title = styled.h1`
  color: ${(props) => props.$theme.colors.dark};
  font-size: 21px;
  font-weight: 600;
  line-height: 110%;
  letter-spacing: 0.5px;
  font-family: ${(props) => props.$theme.secondaryFont};
  padding: 0 15px;
`;

const Text = styled.p`
  color: ${(props) => props.$theme.colors.grey2};
  font-size: 15px;
  font-weight: 300;
  line-height: 150%;
  white-space: initial;
  padding: 0 15px;

  @media (max-width: 600px) {
    font-size: 14px;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  padding: 0px 15px;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  align-self: stretch;

  > button {
    width: 100%;
    font-size: 13px;
    letter-spacing: 1px;
  }
`;

const LearnMoreButton = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 150%;
  font-weight: 100;
  color: ${(props) => props.$theme.colors.grey2};
  cursor: pointer;
  transition: 0.25s ease-in-out all;
  > svg {
    transform: translateX(0px);
    opacity: 0;
    transition: 0.25s ease-in-out all;
    color: ${(props) => props.$theme.colors.dark};
    width: 16px;
  }

  &:hover {
    color: ${(props) => props.$theme.colors.dark};
    > svg {
      opacity: 1;
      transform: translateX(10px);
    }
  }
`;

export default AssessmentCard;
