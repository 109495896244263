import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useTheme } from "@mui/material";

import Img1 from "../../Images/Pages/TeamMember1.png";
import Img2 from "../../Images/Pages/TeamMember2.png";
import Img3 from "../../Images/Pages/TeamMember3.png";
import Img4 from "../../Images/Pages/TeamMember4.png";

import { ReactComponent as PlusIcon } from "../../Icons/Plus.svg";
import { ReactComponent as MinusIcon } from "../../Icons/Minus.svg";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

const slideData = [
  {
    name: "Milad Afdasta",
    post: "Founder",
    img: Img1,
  },
];

function Carousel() {
  const theme = useTheme();
  const [activeDot, setActiveDot] = useState(0);
  const [dots, setDots] = useState([0, 0, 0, 0]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentOpenedSlide, setCurrentOpenedSlide] = useState(null);
  const [visibleSlides, setVisibleSlides] = useState(3);

  const [showNextButton, setShowNextButton] = useState(true);
  const [showPrevButton, setShowPrevButton] = useState(false);

  const moveToCard = (index) => {
    setActiveDot(index);
    setCurrentSlide(index);
  };

  const nextSlide = () => {
    if (currentSlide < slideData.length - visibleSlides) {
      setCurrentSlide(currentSlide + 1);
    }
  };

  const prevSlide = () => {
    if (currentSlide >= 1) {
      setCurrentSlide(currentSlide - 1);
    }
  };

  useEffect(() => {
    // Update dot navigation when current slide changes
    var updatedDots;
    if (visibleSlides > 1) {
      updatedDots = Array(
        Math.round(slideData.length / visibleSlides) + 1
      ).fill(0);
    } else {
      updatedDots = Array(Math.round(slideData.length / visibleSlides)).fill(0);
    }
    setDots(updatedDots);
  }, [visibleSlides, slideData.length]);

  useEffect(() => {
    // Update visible slides based on window width
    const handleResize = () => {
      if (window.innerWidth <= 1200 && window.innerWidth > 768) {
        setVisibleSlides(2);
      } else if (window.innerWidth <= 768) {
        setVisibleSlides(1);
      } else {
        setVisibleSlides(3);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // update visiblity of buttons and active dot
  useEffect(() => {
    if (currentSlide >= 1) {
      setShowPrevButton(true);
    } else {
      setShowPrevButton(false);
    }
    if (currentSlide >= slideData.length / visibleSlides && visibleSlides > 1) {
      setShowNextButton(false);
    } else if (
      visibleSlides === 1 &&
      currentSlide >= slideData.length / visibleSlides - 1
    ) {
      setShowNextButton(false);
    } else {
      setShowNextButton(true);
    }

    setActiveDot(currentSlide);
  }, [currentSlide]);

  return (
    <Container>
      <Slides $visibleSlides={visibleSlides}>
        {slideData
          .slice(currentSlide, currentSlide + visibleSlides)
          .map((slide, i) => (
            <Slide
              $src={slide.img}
              key={i}
              className={currentOpenedSlide === i ? "expanded" : ""}
            >
              <div
                className={
                  currentOpenedSlide === i
                    ? "initial_view closed"
                    : "initial_view"
                }
              >
                <div>
                  <h6>{slide.post}</h6>
                  <h4>{slide.name}</h4>
                </div>
                <div
                  className={
                    currentOpenedSlide === i
                      ? "slide_control active"
                      : "slide_control"
                  }
                  onClick={() => setCurrentOpenedSlide(i)}
                >
                  <PlusIcon />
                </div>
              </div>
              <div
                className={
                  currentOpenedSlide === i ? "slide_view open" : "slide_view"
                }
              >
                <h4>The Dream</h4>
                <p>
                  Driven by a deep empathy for learners. Having faced his own
                  learning challenges, Milad became passionate about using
                  technology to personalize education. This approach, shaped by
                  his experiences and tech expertise, led to Intellarity - a
                  platform designed to help people understand their unique
                  cognitive strengths and learn more effectively. Milad is
                  dedicated to creating a world where education adapts to each
                  individual, driven by a genuine desire to see others succeed.
                  While the journey is ongoing, he's committed to making
                  personalized learning accessible to all, hoping to contribute
                  to a future where everyone can unlock their full potential.
                </p>
                <MinusIcon onClick={() => setCurrentOpenedSlide(null)} />
              </div>
            </Slide>
          ))}
      </Slides>
      {showNextButton && (
        <NavButton onClick={nextSlide} className="next">
          <ChevronRightIcon />
        </NavButton>
      )}
      {showPrevButton && (
        <NavButton onClick={prevSlide} className="prev">
          <ChevronLeftIcon />
        </NavButton>
      )}
      <DotNavigation>
        {dots.map((_, i) => (
          <Dot
            key={i}
            $active={activeDot === i}
            onClick={() => moveToCard(i)}
            $theme={theme}
          />
        ))}
      </DotNavigation>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  overflow-x: hidden;
  margin-top: 50px;
  overflow-y: hidden;
`;

const Slides = styled.div`
  display: grid;
  grid-template-columns: repeat(${(props) => props.$visibleSlides}, 1fr);
  gap: 10px;
  justify-items: center;
  @media (max-width: 768px) {
    grid-template-columns: 1fr !important;
  }

  @media (max-width: 1200px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const Slide = styled.div`
  padding: 0px;
  width: 370px;
  height: 450px;
  overflow-y: hidden;
  border-radius: 36px;

  &.expanded {
    box-shadow: 0px 6px 14px rgba(74, 91, 111, 0.23);
  }

  @media screen and (max-width: 960px) {
    width: 100%;
  }

  .initial_view {
    height: 450px;
    width: 100%;
    background: url(${(props) => props.$src}) no-repeat;
    background-position: center center;
    background-size: 100%;
    transition: 0.5s ease-in-out height, 0.3s ease-in-out background-size,
      filter;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;

    div:first-child {
      margin-bottom: -100px;
      h6 {
        font-weight: 400;
        font-size: 18px;
        line-height: 100%;
        letter-spacing: 1px;
        color: #f6f7f9;
      }
      h4 {
        font-family: "Bricolage Grotesque", sans-serif;
        font-weight: 700;
        font-size: 36px;
        line-height: 100%;
        color: #f6f7f9;
      }
    }
    .slide_control {
      position: absolute;
      bottom: 20px;
      cursor: pointer;
    }

    &:hover {
      filter: saturate(2);
      background-size: 110%;
    }

    &.closed {
      height: 0;
    }
  }

  > .slide_view {
    min-width: 370px;
    min-height: 450px;
    background: #ffffff;
    overflow: hidden;
    position: relative;
    transform: scaleY(0);
    transition: 0.5s ease-in-out;
    transform-origin: bottom;
    padding: 30px;
    h4 {
      font-family: "Bricolage Grotesque", sans-serif;
      font-weight: 600;
      font-size: 21px;
      line-height: 110%;
      letter-spacing: 0.5px;
      color: #202228;
      margin-bottom: 15px;
    }

    p {
      font-weight: 300;
      font-size: 14px;
      line-height: 150%;
      color: #515357;
    }

    svg {
      cursor: pointer;
      position: absolute;
      bottom: 30px;
      left: 50%;
      transform: translateX(-50%);
    }

    &.open {
      transform: scaleY(1);
    }
  }
`;

const Dot = styled.div`
  width: ${(props) => (props.$active ? "30px" : "10px")};
  height: 10px;
  border-radius: 38px;
  margin: 0 3px;
  background: ${(props) =>
    props.$active
      ? "radial-gradient(130.62% 130.62% at 50% 0%, #6DD4F0 0%, #6DD4F0 13.02%, #1A839F 100%)"
      : "#E2E5E9"};
  cursor: pointer;
`;

const DotNavigation = styled.div`
  justify-content: center;
  margin: 30px 0;
  display: flex;
`;

const NavButton = styled.div`
  position: absolute;
  width: 47px;
  height: 47px;
  background: #ffffff;
  box-shadow: 0px 6px 14px rgba(74, 91, 111, 0.23);
  border-radius: 70px;
  cursor: pointer;
  top: 53%;
  display: flex;
  justify-content: center;
  align-items: center;

  &.next {
    right: 40px;
  }

  &.prev {
    left: 40px;
  }
`;

export default Carousel;
