import React from "react";
import styled from "styled-components";

import HeroImgSrc from "../../Images/Home/Hero.png";
import Cloud3 from "../../Images/Cloud3.png";
import { useTheme } from "@mui/material";

import SouthEastIcon from "@mui/icons-material/SouthEast";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

function Section1() {
  const theme = useTheme();
  return (
    <Container>
      <ImageContainer>
        <HeroImg src={HeroImgSrc} />
        <CloudImg src={Cloud3} />
      </ImageContainer>
      <Content $theme={theme}>
        <span>Welcome to</span>
        <Title $theme={theme}>
          <span>Intellarity</span>
        </Title>
        <Body $theme={theme}>Discover Your Unique Cognitive Blueprint</Body>
        {/* <ButtonGroup>
          <TestingButton>
            <span>Start Testing</span>
            <ArrowTilted $theme={theme}>
              <SouthEastIcon />
            </ArrowTilted>
          </TestingButton>
          <InstructionButton $theme={theme}>
            <span>How It Works</span>
            <ArrowRight $theme={theme}>
              <ChevronRightIcon />
            </ArrowRight>
          </InstructionButton>
        </ButtonGroup> */}
        <InfoBoxes>
          <InfoBox $theme={theme}>
            <p>
              Intellarity harnesses cutting-edge AI to decode your unique
              learning style, empowering you to learn faster, retain more, and
              achieve your goals with unprecedented efficiency.
            </p>
          </InfoBox>
          <Line />
          <InfoBox $theme={theme}>
            <p>
              From curious children to ambitious professionals, Intellarity
              adapts to every learner. Experience personalized education that
              evolves with you, making learning an exciting journey of
              continuous growth and discovery.
            </p>
          </InfoBox>
        </InfoBoxes>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

const ImageContainer = styled.div`
  width: 50%;
  height: 550px;
  overflow: hidden;
  @media (max-width: 960px) {
    width: 100%;
    height: 600px;
  }
  @media (max-width: 600px) {
    height: 400px;
  }
`;

const CloudImg = styled.img`
  position: absolute;
  right: -40px;
  z-index: -2;
  transform: rotate(-10deg);
  top: 42vw;
  @media (max-width: 960px) {
    top: 80vw;
    width: 100%;
    height: auto;
  }
  @media (max-width: 600px) {
    top: 85vw;
    width: 100%;
    height: auto;
  }
`;

const HeroImg = styled.img`
  width: auto;
  height: auto;
  position: absolute;
  mix-blend-mode: darken;
  left: 43%;
  top: 0;
  z-index: -2;

  @media (max-width: 1200px) {
    width: 750px;
  }

  @media (max-width: 960px) {
    top: 0px;
    width: auto;
    left: -50px;
  }
  @media (max-width: 600px) {
    top: 18px;
    width: 550px;
    left: -100px;
  }
`;

const Content = styled.div`
  width: 50%;
  padding: 50px 0 50px 110px;
  display: flex;
  flex-direction: column;
  row-gap: 13px;

  > span {
    color: ${(props) => props.$theme.colors.black};
    font-size: 18px;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 1px;
    @media (max-width: 960px) {
      padding: 0 15px;
    }
    @media (max-width: 600px) {
      font-size: 16px;
    }
  }

  @media (max-width: 1200px) {
    padding: 50px 0 50px 55px;
  }
  @media (max-width: 960px) {
    width: 100%;
    padding: 20px 0 50px 0px;
  }
`;

const Title = styled.h1`
  width: 510px;
  color: ${(props) => props.$theme.colors.dark};
  font-family: ${(props) => props.$theme.secondaryFont};
  font-size: 51px;
  font-weight: 700;
  line-height: 100%;

  > span {
    color: ${(props) => props.$theme.colors.accent};
    font-family: ${(props) => props.$theme.secondaryFont};
  }

  @media (max-width: 960px) {
    padding: 0 15px;
  }

  @media (max-width: 600px) {
    width: fit-content;
    font-size: 45px;
  }
`;
const Body = styled.p`
  width: 480px;
  color: ${(props) => props.$theme.colors.black};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;

  @media (max-width: 960px) {
    padding: 0 15px;
  }

  @media (max-width: 600px) {
    width: fit-content;
    font-size: 14px;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 45px;
  width: fit-content;
  margin-top: 20px;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
`;

const TestingButton = styled.div`
  width: 220px;
  height: 57px;
  display: inline-flex;
  padding-left: 0px;
  justify-content: center;
  align-items: center;
  gap: 30px;
  border-radius: 1000px;
  background: #fff;
  box-shadow: 0px 6px 14px 0px rgba(74, 91, 111, 0.23);
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 1px;
  cursor: pointer;
`;

const ArrowTilted = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${(props) => props.$theme.colors.accent};
  width: 57px;
  height: 57px;
  margin: 0 -42px 0px -5px;
  box-shadow: 1px 5px 10px -2px ${(props) => props.$theme.colors.accent};
  > svg {
    color: #fff;
  }
`;

const InstructionButton = styled.div`
  width: 172px;
  height: 57px;
  display: inline-flex;
  padding-left: 0px;
  justify-content: center;
  align-items: center;
  gap: 30px;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 1px;
  color: ${(props) => props.$theme.colors.grey1};
  cursor: pointer;
  @media (max-width: 960px) {
    display: none;
  }
`;
const ArrowRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #fff;
  width: 57px;
  height: 57px;
  margin: 0 -12px 0px -10px;
  box-shadow: 0px 5px 10px 2px rgba(74, 91, 111, 0.23);
  > svg {
    color: ${(props) => props.$theme.colors.dark};
    font-size: 32px;
  }
`;

const InfoBoxes = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 25px;
  flex-wrap: nowrap;
  margin-top: 100px;
  @media (max-width: 960px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 28px;
    padding: 50px 0;
    margin-top: 50px;
    background: #eef1f6;
  }
`;

const InfoBox = styled.div`
  display: flex;
  width: fit-content;
  align-items: flex-start;
  gap: 21px;
  > h1 {
    color: ${(props) => props.$theme.colors.dark};
    font-family: ${(props) => props.$theme.secondaryFont};
    font-size: 46px;
    font-weight: 700;
    line-height: 100%;
  }
  > p {
    color: ${(props) => props.$theme.colors.grey1};
    font-size: 15px;
    font-weight: 100;
    line-height: 150%; /* 21px */
  }

  @media (max-width: 960px) {
    display: flex;
    align-items: flex-start;
    column-gap: 25px;
    padding: 0 15px;
    > p {
      width: 80%;
    }
  }
`;

const Line = styled.div`
  width: 80%;
  margin: 18px auto;
  height: 1px;
  background-color: #c7c8cb;
  @media (min-width: 960px) {
    display: none;
  }
`;

export default Section1;
